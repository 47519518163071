<template>
  <div>
    <div :class="!mainloader ? 'd-none' : ''" class="no-scroll no-xhide">
      <img class="pos-cent blinkimg" width="90px" :src="require(`@/assets/${$vuetify.theme.dark ? 'logod' : 'logo'}.svg`)" alt="wait" />
    </div>
    <div :class="mainloader ? 'd-none' : ''">
      <AppBar />
      <div>
        <v-container fluid color="mainbg">
          <div class="px-0 px-md-0 mb-12 mb-md-0">
            <v-snackbars :objects.sync="wsorderalertdata" bottom right>
              <template v-slot:action="{close}">
                <v-icon @click="close()" class="float-right" color="#fff">mdi-close-circle</v-icon>
              </template>
            </v-snackbars>
            <v-snackbar class="d-none d-md-flex snakbar-sty rounded-pill mt-16" transition="slide-x-reverse-transition" top right v-model="snackbar" timeout="4000" :value="true" :color="snackcolor" absolute text-color="white">
              <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
              {{ snacktxt }}
              <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
            </v-snackbar>

            <v-snackbar class="d-md-none snakbar-sty rounded-pill" transition="slide-x-reverse-transition" v-model="snackbar" timeout="4000" :value="true" :color="snackcolor" absolute text-color="white">
              <v-icon class="mr-2" color="#fff">mdi-alert-outline</v-icon>
              {{ snacktxt }}
              <v-icon @click="snackbar = false" class="float-right" color="#fff">mdi-close-circle</v-icon>
            </v-snackbar>
            <v-row no-glutters class="pt-14">
              <v-col cols="12" md="9" class="pb-0 pr-1 pl-2">
                <router-view @snack="snackAlert" @menudialog="setMenudialog" @gologin="goSso" @loader="setLoaderstatus" />
              </v-col>
              <v-col cols="3" class="pt-2 pos-rlt pb-0 pr-1 pl-1 d-none d-md-block" :class="uid ? 'sec-watchlist' : ''">
                <div v-if="uid" style="position: sticky !important; top: 64px !important; left: 0">
                  <WatchList />
                </div>
                <div v-else class="pos-md-stk top-md-108 px-4 px-md-0">
                  <v-card outlined class="overflow-hidden ss-login-card pt-md-3 px-3 rounded-lg text-center pos-md-rlt crd-trn" width="100%">
                    <img :src="require(`@/assets/products-sec/${bodytab == 'Mutual funds' ? 'mutual-funds' : bodytab == 'IPOs' ? 'ipos' : bodytab == 'Bonds' ? 'bonds' : 'Stocks-ETFs'}.png`)" :alt="bodytab" class="mb-md-2 mt-md-4 px-md-4 px-16" width="100%" />
                    <p class="font-weight-bold fs-16 mb-1">Ready to invest in {{ bodytab }}?</p>
                    <p class="subtext--text font-weight-medium fs-12 mb-8">
                      Open your demat account effortlessly <br />
                      with our six-click process.
                    </p>
                    <v-card class="pos-md-abs pt-6 pt-md-0 btm-32 elevation-0 rounded-0 crd-trn" width="100%">
                      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()" class="pr-md-6">
                        <v-text-field
                          v-model="mobile"
                          @keypress="NumberValid($event)"
                          @keyup.enter="getCall()"
                          :maxlength="10"
                          :rules="numberis"
                          required
                          hide-spin-buttons
                          flat
                          hide-details
                          solo
                          background-color="secbg"
                          class="sign-up-filed elevation-0 rounded-pill caption mb-3"
                          label="Enter mobile number to begin"
                        >
                          <template #prepend-inner>
                            <img :src="require(`@/assets/phone-icon${$vuetify.theme.dark ? 'd' : ''}.svg`)" width="100%" alt="phone-icon" />
                          </template>
                        </v-text-field>

                        <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07E30'" class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Invest now</span></v-btn>
                      </v-form>
                    </v-card>
                  </v-card>
                </div>
              </v-col>
            </v-row>
            <v-dialog v-if="menudata[0]" v-model="alertdialog" hide-overlay width="480px">
              <v-card class="pb-6 overflow-hidden" color="cardbg">
                <v-card class="elevation-0 py-4 rounded-b-0" color="secbg">
                  <v-toolbar class="elevation-0 px-2 crd-trn" dense>
                    <v-list-item class="px-0">
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold fs-16 maintext--text mb-2"
                          >{{ menudata[0] ? menudata[0].tsym : "" }} <span class="ml-1 txt-999 fs-10">{{ menudata[0] ? menudata[0].exch : "" }}</span></v-list-item-title
                        >
                        <v-list-item-title class="maintext--text font-weight-bold fs-14 mb-1"
                          >₹<span id="laypopltp">{{ menudata.ltp ? menudata.ltp : "0.00" }}</span> &nbsp;
                          <span class="fs-12" id="laypopchpclr" :class="menudata.ch > 0 ? 'maingreen--text' : menudata.ch < 0 ? 'mainred--text' : 'subtext--text'">
                            <span id="laypopch">{{ menudata && menudata.ch ? `${menudata.ch}` : "0.00" }} </span>
                            (<span id="laypopchp">{{ menudata && menudata.chp ? `${menudata.chp}` : "0.00" }}</span
                            >%)</span
                          ></v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="alertloader" @click="closeMenudialog('alert')" small icon>
                      <v-icon color="maintext">mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-progress-linear v-if="alertloader" indeterminate></v-progress-linear>
                </v-card>

                <div class="px-6 pt-4 pb-2">
                  <p class="font-weight-bold fs-14">{{ menudata.malert ? "Modify" : "Set" }} alert Price</p>
                  <v-row no-glutters>
                    <v-col cols="4" class="pb-0">
                      <p class="font-weight-bold fs-14 mb-2">Alert me</p>
                      <v-select dense @change="alertis == 'VOLUME' ? (condition = '>') : alertis == '52HIGH' ? (alertvalue = 0) : ''" v-model="alertis" :items="alertitems" append-icon="mdi-chevron-down" background-color="secbg" flat class="rounded-pill" solo></v-select>
                    </v-col>
                    <v-col cols="4" class="pb-0">
                      <p class="font-weight-bold fs-14 mb-2">Condition</p>
                      <v-select dense :disabled="alertis == 'VOLUME'" v-model="condition" :items="['>', '<']" append-icon="mdi-chevron-down" background-color="secbg" flat class="rounded-pill" solo></v-select>
                    </v-col>
                    <v-col cols="4" class="pb-0">
                      <p class="font-weight-bold fs-14 mb-2">Enter Value</p>
                      <v-text-field dense v-if="alertis != '52HIGH'" background-color="secbg" v-model="alertvalue" flat class="rounded-pill" solo type="number" hide-spin-buttons min="0"></v-text-field>
                      <v-text-field v-else disabled background-color="secbg" value="NA" flat class="rounded-pill" solo type="text"></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="4" class="pb-0">
                      <p class="font-weight-bold fs-14 mb-2">Validity</p>
                      <v-text-field background-color="secbg" readonly value="Good Till Trigger" flat dense class="rounded-pill" solo type="text"> </v-text-field>
                    </v-col>
                    <v-col cols="8" class="pb-0">
                      <p class="font-weight-bold fs-14 mb-2">Remarks</p>
                      <v-text-field background-color="secbg" v-model="alertremarks" flat class="rounded-pill" solo dense type="text"> </v-text-field>
                    </v-col>
                  </v-row>
                  <v-btn @click="setAlert()" :disabled="alertis == '52HIGH' ? false : alertvalue == 0" :loading="alertloader" color="btnclr" class="text-none rounded-pill elevation-0 btntext--text px-10 mt-4 float-right" height="40px"> {{ menudata.malert ? "Modify" : "Set" }} alert </v-btn>
                </div>
              </v-card>
            </v-dialog>
            <v-dialog v-if="menudata.sliceorder" v-model="menudata.sliceorder" persistent hide-overlay width="380px">
              <v-card class="mx-auto py-2 pb-4 text-right rounded-xl elevation-0" color="cardbg" width="100%">
                <v-toolbar class="elevation-0 mb-0" color="transbarent" dense>
                  <span class="font-weight-bold fs-14">Slice Order</span>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="orderloader" @click="(menudata.sliceorder = false), ((orderdialog = true), setDragableSett())" color="maintext" x-small fab outlined>
                    <v-icon color="maintext">mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-divider class="mb-2"></v-divider>
                <div class="px-4">
                  <v-row class="pb-4">
                    <v-col cols="6" class="text-left pb-">
                      <p class="font-weight-bold fs-14 maintext--text mb-0">
                        {{ menudata[0] ? menudata[0].tsym : "" }}
                        <span class="ml-1 txt-999 fs-10">{{ menudata[0] ? menudata[0].exch : "" }}</span>
                      </p>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <p class="font-weight-bold fs-14 maintext--text mb-0">
                        {{ this.menudata.fzqty[0] }} <span class="fs-12 grey--text">x {{ this.menudata.fq == 1 ? "1" : this.menudata.fq - 1 }}</span>
                      </p>
                    </v-col>
                    <v-col cols="6" class="text-left pb-0">
                      <p class="font-weight-bold fs-14 maintext--text mb-0">
                        {{ menudata[0] ? menudata[0].tsym : "" }}
                        <span class="ml-1 txt-999 fs-10">{{ menudata[0] ? menudata[0].exch : "" }}</span>
                      </p>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <p class="font-weight-bold fs-14 maintext--text mb-0">
                        {{ this.menudata.fzqty[this.menudata.fq] }}
                        <span class="fs-12 grey--text">x 1</span>
                      </p>
                    </v-col>
                  </v-row>

                  <v-btn block @click="setUPPlaceorder(true)" :loading="orderloader" :color="buyrsell ? 'mainred' : 'maingreen'" class="text-none rounded-pill elevation-0 white--text px-6" height="40px">
                    {{ buyrsell ? "Sell" : "Buy" }}
                  </v-btn>
                </div>
              </v-card>
            </v-dialog>
            <!-- <v-dialog v-model="orderdialog" @click:outside="closeMenudialog('order')" hide-overlay width="540"> -->
            <v-card v-if="orderdialog" id="maindiv" class="pb-5 overflow-hidden rounded-lg" color="cardbg" width="540px">
              <v-card id="maindivheader" :loading="orderloader" class="elevation-0 pt-6 pb-4 rounded-b-0" :color="!buyrsell ? 'secgreen' : 'secred'">
                <v-toolbar class="elevation-0 px-2 crd-trn" dense>
                  <v-list-item class="px-0">
                    <!-- <v-list-item-content>
                      <v-list-item-title class="font-weight-bold fs-16 maintext--text mb-2">{{ menudata ? menudata.symname :
                        '' }}
                        <span class="ml-1 txt-999 fs-10">{{ menudata ? menudata.exch : '' }}</span></v-list-item-title>
                      <v-radio-group v-model="orderexch" row>
                        <v-radio color="#000" label="BSE : ₹3,288.90" value="bse"></v-radio>
                        <v-radio color="#000" label="NSE : ₹3,288.50" value="nse"></v-radio>
                      </v-radio-group>
                    </v-list-item-content> -->
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold fs-16 maintext--text mb-2"
                        >{{ menudata[0] ? menudata[0].tsym : "" }} <span class="ml-1 subtext--text fs-10">{{ menudata[0] ? menudata[0].exch : "" }}</span></v-list-item-title
                      >
                      <v-list-item-title class="maintext--text font-weight-bold fs-14 mb-1"
                        >₹<span id="laypopltp">{{ menudata.ltp ? menudata.ltp : "0.00" }}</span> &nbsp;
                        <span class="fs-12" id="laypopchpclr" :class="menudata.ch > 0 ? 'maingreen--text' : menudata.ch < 0 ? 'mainred--text' : 'subtext--text'">
                          <span id="laypopch">{{ menudata && menudata.ch ? `${menudata.ch}` : "0.00" }} </span>
                          (<span id="laypopchp">{{ menudata && menudata.chp ? `${menudata.chp}` : "0.00" }}</span
                          >%)</span
                        ></v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-spacer></v-spacer>
                  <v-card class="rounded-md elevation-0 py-1 px-2 font-weight-bold fs-10 white--text mr-4 mt-n5" color="maingreen"> B </v-card>
                  <v-switch :disabled="menudata.settype && menudata.settype == 'mod'" v-model="buyrsell" inset></v-switch>
                  <v-card class="rounded-md elevation-0 py-1 px-2 font-weight-bold fs-10 white--text ml-1 mt-n5" color="#FF1717"> S </v-card>
                </v-toolbar>
              </v-card>
              <div v-if="menudata[0]" style="height: calc(100vh - 232px) !important" class="overflow-y-auto pos-rlt">
                <v-tabs @change="setOrderTabs()" color="maintext" v-model="ordertype" class="px-6">
                  <template v-for="(t, index) in orderselect">
                    <v-tab
                      v-if="index == 4 ? ((menudata[0] && menudata[0].exch == 'NSE') || menudata[0].exch == 'BSE' ? true : false) : true"
                      :disabled="menudata.settype == 'mod' || gttBSM == 'Modify' ? (menudata.settype == 'mod' && ordertype == index ? false : gttBSM == 'Modify' && index == 3 ? false : true) : false"
                      fixed
                      :key="index"
                      class="text-none text-start"
                      dense
                      style="min-width: fit-content"
                    >
                      <span class="text-center" style="font-size: 14px; letter-spacing: -0.7px; font-weight: 469; line-height: 10px; width: fit-content">{{ t }}</span>
                    </v-tab>
                  </template>

                  <v-tabs-slider class="" color="maintext"></v-tabs-slider>
                </v-tabs>
                <v-divider></v-divider>
                <div class="px-6 pt-4 pb-2">
                  <div v-if="ordertype == 0 || ordertype == 3" class="mb-3">
                    <p class="subtext--text fs-14 font-weight-regular mb-0">Investment type</p>
                    <v-radio-group v-model="investype" row hide-details>
                      <v-radio color="maintext" label="Intraday" value="I"></v-radio>
                      <v-radio v-if="menudata[0].exch == 'NSE' || menudata[0].exch == 'BSE'" color="maintext" label="Delivery" value="C"></v-radio>
                      <v-radio v-else color="maintext" label="Carry Forward" value="M"></v-radio>
                    </v-radio-group>
                  </div>
                  <!-- <v-row no-glutters>
                    <v-col  cols="4">
                     
                    </v-col>
                    <v-col :cols="ordertype == 0 ? '8' : '12'"> -->
                  <p class="subtext--text fs-14 font-weight-regular mb-0">Select order type</p>
                  <v-chip-group @change="ordertype != 3 ? getMargin() : '', setOrdertypes()" row>
                    <v-chip @click="prc = tag.val" :color="prc == tag.val ? 'maintext' : 'secbg'" :text-color="prc == tag.val ? 'mainbg' : 'maintext'" v-for="(tag, l) in ordertype == 0 || ordertype == 3 ? prcitems : prc_c_items" :key="l">
                      {{ tag.txt }}
                    </v-chip>
                  </v-chip-group>
                  <!-- </v-col>
                  </v-row> -->
                  <v-divider class="mt-2 mb-3"></v-divider>
                  <div v-if="ordertype != 3">
                    <v-row no-glutters class="pb-2">
                      <v-col cols="6">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">
                          Quantity <span class="float-right mr-2">MLot: {{ menudata[0] ? menudata[0].ls : "-" }}</span>
                        </p>
                        <v-text-field @input="getMargin()" height="40px" dense background-color="secbg" hide-details flat block class="rounded-pill" solo type="number" hide-spin-buttons :min="menudata[0].ls" :step="menudata[0].ls" v-model="ordqty">
                          <template #append>
                            <v-btn @click="ordqty = Number(ordqty) + Number(menudata[0].ls)" icon class="elevation-0">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <circle cx="12" cy="12" r="12" fill="white" />
                                <path d="M12 8V16" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                                <path d="M16 12L8 12" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                              </svg>
                            </v-btn>
                          </template>

                          <template #prepend-inner>
                            <v-btn @click="ordqty == menudata[0].ls ? ordqty : (ordqty = Number(ordqty) - Number(menudata[0].ls))" icon class="elevation-0">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <circle cx="12" cy="12" r="12" fill="white" />
                                <path d="M16 12L8 12" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                              </svg>
                            </v-btn>
                          </template>
                        </v-text-field>
                        <p v-if="menudata[1]" class="lh-16 fs-10 subtext--text mb-0">Freeze qty: {{ menudata[1].frzqty }}</p>
                      </v-col>
                      <v-col cols="6">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Price</p>
                        <v-text-field @input="getMargin()" :readonly="prc == 'MKT' || prc == 'SL-MKT'" height="40px" hide-details dense background-color="secbg" flat block class="rounded-pill" solo type="number" hide-spin-buttons :min="menudata[0].ti" :step="menudata[0].ti" v-model="ordprice">
                          <template #append>
                            <svg v-if="prc == 'MKT' || prc == 'SL-MKT'" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                              <path d="M10.0625 4.8125H9.1875V3.0625C9.1875 1.85456 8.20794 0.875 7 0.875C5.79206 0.875 4.8125 1.85456 4.8125 3.0625V4.8125H3.9375V3.0625C3.9375 1.37112 5.30862 0 7 0C8.69137 0 10.0625 1.37112 10.0625 3.0625V4.8125Z" fill="#999999" />
                              <path
                                d="M11.5938 5.6875H2.40625C1.80206 5.6875 1.3125 6.17706 1.3125 6.78125V12.9062C1.3125 13.5104 1.80206 14 2.40625 14H11.5938C12.1979 14 12.6875 13.5104 12.6875 12.9062V6.78125C12.6875 6.17706 12.1979 5.6875 11.5938 5.6875ZM7.4375 10.8754V11.8125C7.4375 12.054 7.2415 12.25 7 12.25C6.7585 12.25 6.5625 12.054 6.5625 11.8125V10.8754C5.62669 10.6339 5.06406 9.67925 5.30556 8.74344C5.54706 7.80763 6.50169 7.245 7.4375 7.4865C8.37331 7.728 8.93594 8.68263 8.69444 9.61844C8.53519 10.2349 8.05394 10.7161 7.4375 10.8754Z"
                                fill="#999999"
                              />
                            </svg>
                          </template>

                          <template #prepend-inner>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="white" />
                              <path
                                d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                                fill="#999999"
                              />
                            </svg>
                          </template>
                        </v-text-field>
                        <p v-if="menudata[1]" class="lh-16 fs-10 subtext--text mb-0">Circuit level: {{ menudata[1].lc }} - {{ menudata[1].uc }}</p>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-2"></v-divider>
                    <v-row no-glutters>
                      <v-col v-if="prc == 'SL-LMT' || prc == 'SL-MKT'" cols="4" class="pb-0">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Trigger</p>
                        <v-text-field :disabled="prc != 'SL-LMT' && prc != 'SL-MKT'" height="40px" dense background-color="secbg" flat block class="rounded-pill" solo type="number" hide-spin-buttons min="0" v-model="ordtrgprice">
                          <template #prepend-inner>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="white" />
                              <path
                                d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                                fill="#999999"
                              />
                            </svg>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col v-if="ordertype == 2" cols="4" class="pb-0">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Target</p>
                        <v-text-field height="40px" dense background-color="secbg" flat block class="rounded-pill" solo type="number" hide-spin-buttons min="0" v-model="ordtagprice">
                          <template #prepend-inner>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="white" />
                              <path
                                d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                                fill="#999999"
                              />
                            </svg>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col v-if="ordertype != 0" cols="4" class="pb-0">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Stoploss</p>
                        <v-text-field height="40px" dense background-color="secbg" flat block class="rounded-pill" solo type="number" hide-spin-buttons min="0" v-model="ordslprice">
                          <template #prepend-inner>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="white" />
                              <path
                                d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                                fill="#999999"
                              />
                            </svg>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider v-if="ordertype == 1 || ordertype == 2 || prc == 'SL-LMT' || prc == 'SL-MKT'" color="#F1F2F4" class="mb-2"></v-divider>
                    <v-row no-glutters>
                      <v-col cols="12">
                        <v-checkbox color="maintext" v-model="addvalqty" hide-details>
                          <template #label>
                            <p class="font-weight-regular fs-14 subtext--text mb-0">Add Validity & Disclosed Qty</p>
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col v-if="addvalqty" cols="6" class="py-0">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Validity</p>
                        <v-chip-group @change="getMargin()" column>
                          <v-chip v-if="menudata[0].exch != 'BSE'" @click="ordvalqtyprice = 'IOC'" :color="ordvalqtyprice == 'IOC' ? 'maintext' : 'secbg'" :text-color="ordvalqtyprice == 'IOC' ? 'mainbg' : 'maintext'"> IOC </v-chip>
                          <v-chip v-if="(menudata[0].exch == 'BFO' || menudata[0].exch == 'BCD') && menudata[0].exch != 'BSE'" @click="ordvalqtyprice = 'EOS'" :color="ordvalqtyprice == 'EOS' ? 'maintext' : 'secbg'" :text-color="ordvalqtyprice == 'EOS' ? 'mainbg' : 'maintext'"> EOS </v-chip>
                          <v-chip v-else @click="ordvalqtyprice = 'DAY'" :color="ordvalqtyprice == 'DAY' ? 'maintext' : 'secbg'" :text-color="ordvalqtyprice == 'DAY' ? 'mainbg' : 'maintext'"> Day </v-chip>
                        </v-chip-group>
                      </v-col>
                      <v-col v-if="addvalqty" cols="6" class="py-0">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Disclosed Qty</p>
                        <v-text-field height="40px" dense background-color="secbg" flat class="rounded-pill" solo hide-details type="number" hide-spin-buttons min="0" v-model="orddisqty">
                          <template #append>
                            <v-btn @click="orddisqty++" icon class="elevation-0">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <circle cx="12" cy="12" r="12" fill="white" />
                                <path d="M12 8V16" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                                <path d="M16 12L8 12" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                              </svg>
                            </v-btn>
                          </template>

                          <template #prepend-inner>
                            <v-btn @click="orddisqty == 0 ? orddisqty : orddisqty--" icon class="elevation-0">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <circle cx="12" cy="12" r="12" fill="white" />
                                <path d="M16 12L8 12" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                              </svg>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-2" :class="addvalqty ? 'mt-4' : 'mt-3'"></v-divider>
                    <div>
                      <v-checkbox :disabled="menudata.settype && menudata.settype == 'mod'" color="maintext" v-model="addmktord" hide-details>
                        <template #label>
                          <p class="font-weight-regular fs-14 subtext--text mb-0">Add Market order (AMO)</p>
                        </template>
                      </v-checkbox>

                      <div v-if="prc == 'MKT' || prc == 'SL-MKT'">
                        <v-divider class="my-4"></v-divider>
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Market Production %</p>
                        <v-text-field @input="getMargin()" height="40px" dense background-color="secbg" flat hide-details class="rounded-pill" solo type="number" hide-spin-buttons min="2" max="100" step="1" v-model="mktprdordpec"> </v-text-field>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <v-row no-glutters>
                      <v-col cols="4" class="pb-0">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Alert</p>
                        <v-select
                          :disabled="gttocopanel || gttBSM == 'Modify'"
                          height="40px"
                          @change="gttalert == 'VOLUME' ? (gttcond = '>') : ''"
                          :items="gttcond == '>' ? gttalertitemso : gttalertitemst"
                          append-icon="mdi-chevron-down"
                          dense
                          background-color="secbg"
                          flat
                          block
                          class="rounded-pill"
                          solo
                          v-model="gttalert"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="4" class="pb-0">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Condition</p>
                        <v-select :disabled="gttocopanel || gttalert == 'VOLUME' || gttBSM == 'Modify'" height="40px" :items="gttconitem" append-icon="mdi-chevron-down" dense background-color="secbg" flat block class="rounded-pill" solo v-model="gttcond"> </v-select>
                      </v-col>
                      <v-col cols="4" class="pb-0">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Value</p>
                        <v-text-field @keyup="setOrdertypes()" height="40px" dense background-color="secbg" flat block class="rounded-pill" min="0" solo type="number" hide-spin-buttons v-model="gttvalue"> </v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-2"></v-divider>
                    <v-row no-glutters>
                      <v-col cols="4" class="pb-0">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Quantity</p>
                        <v-text-field @keyup="setOrdertypes()" height="40px" dense background-color="secbg" flat block class="rounded-pill" solo type="number" hide-spin-buttons :min="menudata[0].ls" :step="menudata[0].ls" v-model="gttqty">
                          <template #append>
                            <v-btn @click="gttqty = Number(gttqty) + Number(menudata[0].ls)" icon class="elevation-0">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <circle cx="12" cy="12" r="12" fill="white" />
                                <path d="M12 8V16" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                                <path d="M16 12L8 12" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                              </svg>
                            </v-btn>
                          </template>

                          <template #prepend-inner>
                            <v-btn @click="gttqty == menudata[0].ls ? gttqty : (gttqty = Number(gttqty) - Number(menudata[0].ls))" icon class="elevation-0">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <circle cx="12" cy="12" r="12" fill="white" />
                                <path d="M16 12L8 12" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                              </svg>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="4" class="pb-0">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Price</p>
                        <v-text-field :disabled="prc == 'MKT' || prc == 'SL-MKT'" height="40px" dense background-color="secbg" flat block class="rounded-pill" solo type="number" hide-spin-buttons min="0" v-model="gttprice">
                          <template #append>
                            <svg v-if="prc == 'MKT' || prc == 'SL-MKT'" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                              <path d="M10.0625 4.8125H9.1875V3.0625C9.1875 1.85456 8.20794 0.875 7 0.875C5.79206 0.875 4.8125 1.85456 4.8125 3.0625V4.8125H3.9375V3.0625C3.9375 1.37112 5.30862 0 7 0C8.69137 0 10.0625 1.37112 10.0625 3.0625V4.8125Z" fill="#999999" />
                              <path
                                d="M11.5938 5.6875H2.40625C1.80206 5.6875 1.3125 6.17706 1.3125 6.78125V12.9062C1.3125 13.5104 1.80206 14 2.40625 14H11.5938C12.1979 14 12.6875 13.5104 12.6875 12.9062V6.78125C12.6875 6.17706 12.1979 5.6875 11.5938 5.6875ZM7.4375 10.8754V11.8125C7.4375 12.054 7.2415 12.25 7 12.25C6.7585 12.25 6.5625 12.054 6.5625 11.8125V10.8754C5.62669 10.6339 5.06406 9.67925 5.30556 8.74344C5.54706 7.80763 6.50169 7.245 7.4375 7.4865C8.37331 7.728 8.93594 8.68263 8.69444 9.61844C8.53519 10.2349 8.05394 10.7161 7.4375 10.8754Z"
                                fill="#999999"
                              />
                            </svg>
                          </template>
                          <template #prepend-inner>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="white" />
                              <path
                                d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                                fill="#999999"
                              />
                            </svg>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col v-if="prc == 'SL-LMT' || prc == 'SL-MKT'" cols="4" class="pb-0">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Trigger</p>
                        <v-text-field height="40px" dense background-color="secbg" flat block class="rounded-pill" solo type="number" hide-spin-buttons min="0" v-model="gtttrgprice">
                          <template #prepend-inner>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="white" />
                              <path
                                d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                                fill="#999999"
                              />
                            </svg>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider class="mb-2"></v-divider>

                    <v-btn class="font-weight-bold" :disabled="gttBSM == 'Modify'" :color="gttocopanel ? 'primary' : 'maintext'" text height="40px" block @click="(gttocopanel = !gttocopanel), gttocopanel ? ((gttalert = 'LTP'), (gttcond = '>')) : ((gttalert = 'LTP'), (gttcond = '<'))"
                      >OCO
                      <span class="float-right"
                        ><v-icon>{{ gttocopanel ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon></span
                      ></v-btn
                    >
                    <div v-if="gttocopanel" class="mt-2">
                      <p class="subtext--text fs-14 font-weight-regular mb-0">Select order type</p>
                      <v-chip-group @change="setOrdertypes()" class="mb-3" row>
                        <v-chip @click="ocoprc = tag.val" :color="ocoprc == tag.val ? 'maintext' : 'secbg'" :text-color="ocoprc == tag.val ? 'mainbg' : 'maintext'" v-for="(tag, l) in prcitems" :key="l">
                          {{ tag.txt }}
                        </v-chip>
                      </v-chip-group>
                      <v-row no-glutters>
                        <v-col cols="4" class="pb-0">
                          <p class="font-weight-regular fs-14 subtext--text mb-2">Alert</p>
                          <v-select disabled value="LTP" :items="['LTP']" height="40px" append-icon="mdi-chevron-down" dense background-color="secbg" flat block class="rounded-pill" solo> </v-select>
                        </v-col>
                        <v-col cols="4" class="pb-0">
                          <p class="font-weight-regular fs-14 subtext--text mb-2">Condition</p>
                          <v-select disabled value="<" :items="['<']" height="40px" append-icon="mdi-chevron-down" dense background-color="secbg" flat block class="rounded-pill" solo> </v-select>
                        </v-col>
                        <v-col cols="4" class="pb-0">
                          <p class="font-weight-regular fs-14 subtext--text mb-2">Value</p>
                          <v-text-field @keyup="setOrdertypes()" height="40px" dense background-color="secbg" flat block class="rounded-pill" min="0" solo type="number" hide-spin-buttons v-model="ocovalue"> </v-text-field>
                        </v-col>
                      </v-row>
                      <v-divider class="mb-2"></v-divider>
                      <v-row no-glutters>
                        <v-col cols="4" class="pb-0">
                          <p class="font-weight-regular fs-14 subtext--text mb-2">Quantity</p>
                          <v-text-field @keyup="setOrdertypes()" height="40px" dense background-color="secbg" flat block class="rounded-pill" solo type="number" hide-spin-buttons :min="menudata[0].ls" :step="menudata[0].ls" v-model="ocoqty">
                            <template #append>
                              <v-btn @click="ocoqty = Number(ocoqty) + Number(menudata[0].ls)" icon class="elevation-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <circle cx="12" cy="12" r="12" fill="white" />
                                  <path d="M12 8V16" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                                  <path d="M16 12L8 12" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                                </svg>
                              </v-btn>
                            </template>

                            <template #prepend-inner>
                              <v-btn @click="ocoqty == menudata[0].ls ? ocoqty : (ocoqty = Number(ocoqty) - Number(menudata[0].ls))" icon class="elevation-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <circle cx="12" cy="12" r="12" fill="white" />
                                  <path d="M16 12L8 12" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                                </svg>
                              </v-btn>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="4" class="pb-0">
                          <p class="font-weight-regular fs-14 subtext--text mb-2">Price</p>
                          <v-text-field :disabled="ocoprc == 'MKT' || ocoprc == 'SL-MKT'" height="40px" dense background-color="secbg" flat block class="rounded-pill" solo type="number" hide-spin-buttons :min="menudata[0].lc" :max="menudata[0].uc" v-model="ocoprice">
                            <template #append>
                              <svg v-if="ocoprc == 'MKT' || ocoprc == 'SL-MKT'" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M10.0625 4.8125H9.1875V3.0625C9.1875 1.85456 8.20794 0.875 7 0.875C5.79206 0.875 4.8125 1.85456 4.8125 3.0625V4.8125H3.9375V3.0625C3.9375 1.37112 5.30862 0 7 0C8.69137 0 10.0625 1.37112 10.0625 3.0625V4.8125Z" fill="#999999" />
                                <path
                                  d="M11.5938 5.6875H2.40625C1.80206 5.6875 1.3125 6.17706 1.3125 6.78125V12.9062C1.3125 13.5104 1.80206 14 2.40625 14H11.5938C12.1979 14 12.6875 13.5104 12.6875 12.9062V6.78125C12.6875 6.17706 12.1979 5.6875 11.5938 5.6875ZM7.4375 10.8754V11.8125C7.4375 12.054 7.2415 12.25 7 12.25C6.7585 12.25 6.5625 12.054 6.5625 11.8125V10.8754C5.62669 10.6339 5.06406 9.67925 5.30556 8.74344C5.54706 7.80763 6.50169 7.245 7.4375 7.4865C8.37331 7.728 8.93594 8.68263 8.69444 9.61844C8.53519 10.2349 8.05394 10.7161 7.4375 10.8754Z"
                                  fill="#999999"
                                />
                              </svg>
                            </template>
                            <template #prepend-inner>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="white" />
                                <path
                                  d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                                  fill="#999999"
                                />
                              </svg>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col v-if="ocoprc == 'SL-LMT' || ocoprc == 'SL-MKT'" cols="4" class="pb-0">
                          <p class="font-weight-regular fs-14 subtext--text mb-2">Trigger</p>
                          <v-text-field height="40px" dense background-color="secbg" flat block class="rounded-pill" solo type="number" hide-spin-buttons min="0" v-model="ocotrgprice">
                            <template #prepend-inner>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="white" />
                                <path
                                  d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                                  fill="#999999"
                                />
                              </svg>
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </div>
              <v-toolbar v-if="ordertype != 3" class="tool-sty elevation-0 pt-4 mb-3 px-6" color="cardbg" dense>
                <v-list-item class="px-0">
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-medium fs-12 subtext--text mb-1"
                      >Margin:
                      <span class="ml-1 primary--text"
                        ><b>₹{{ (menudata && menudata.margin) > 0 ? menudata.margin : "0.00" }}</b></span
                      >
                      <v-icon class="ml-1 cursor-p" @click="getMargin()" color="maintext" size="12">mdi-reload</v-icon>
                    </v-list-item-title>
                    <v-list-item-title class="font-weight-medium fs-12 subtext--text mb-1"
                      >Charges:
                      <span class="ml-1 primary--text"
                        ><b>₹{{ (menudata && menudata.charges) > 0 ? menudata.charges : "0.00" }}</b></span
                      ></v-list-item-title
                    >
                    <v-list-item-title v-if="menudata.mremarks" class="font-weight-medium fs-12 subtext--text mb-0"
                      >Remarks:
                      <span class="ml-1 mainred--text"
                        ><b>{{ menudata.mremarks ? menudata.mremarks : "-" }}</b></span
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-spacer></v-spacer>
                <v-btn @click="closeMenudialog('order')" color="secbg" :disabled="orderloader" class="text-none rounded-pill elevation-0 subtext--text px-6" height="40px"> Cancel </v-btn>
                <v-btn @click="setUPPlaceorder()" :loading="orderloader" :color="buyrsell ? 'mainred' : 'maingreen'" class="text-none rounded-pill elevation-0 white--text px-6 ml-4" height="40px">
                  {{ menudata.settype && menudata.settype == "mod" ? "Modify" : buyrsell ? "Sell" : "Buy" }}
                </v-btn>
              </v-toolbar>
              <v-toolbar v-else class="tool-sty elevation-0 pt-4 mb-3 px-6" color="cardbg" dense>
                <v-spacer></v-spacer>
                <v-btn @click="closeMenudialog('order')" color="secbg" :disabled="orderloader" class="text-none rounded-pill elevation-0 subtext--text px-6" height="40px"> Cancel </v-btn>
                <v-btn @click="setGttplaceorder()" :loading="orderloader" :disabled="gttplacebtn" :color="buyrsell ? 'mainred' : 'maingreen'" class="text-none rounded-pill elevation-0 white--text px-6 ml-4" height="40px">
                  {{ gttBSM == "Modify" ? "Modify" : "Create" }} {{ gttocopanel ? "OCO" : "GTT" }}
                </v-btn>
              </v-toolbar>
            </v-card>
            <!-- </v-dialog> -->

            <v-dialog v-if="menudata.item" v-model="mforderdialog" persistent @click:outside="!orderpoploader || !menudata.payment ? closeMenudialog('mforder') : ''" :width="menudata.payment ? '640' : '400'">
              <v-card class="pb-5 overflow-hidden" color="cardbg">
                <v-card class="elevation-0 pt-6 pb-2" color="secbg">
                  <P v-if="mf_tenure == null" class="font-weight-bold fs-16 maintext--text mb-2 px-4 px-md-6">Create mandate </P>

                  <div class="px-4 px-md-6" v-if="mf_tenure != null && menudata.types == 'redem'">
                    <P class="font-weight-bold fs-16 maintext--text mb-1">Redemption</P>
                    <p class="font-weight-bold fs-13 subtext--text mb-0">{{ menudata.sname ? menudata.sname : "" }} </p>
                  </div>

                  <v-list-item v-if="mf_tenure != null && menudata.types != 'redem'" class="px-4 px-md-6">
                    <v-list-item-content class="py-0">
                      <v-list-item-title class="font-weight-bold fs-16 maintext--text mb-0">{{ menudata.item ? menudata.item.f_scheme_name : "" }} </v-list-item-title>

                      <v-chip-group column class="mb-1 pt-0 mt-0">
                        <v-chip color="cardbg" x-small text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
                          <span class="font-weight-medium fs-10">
                            {{ menudata.item.splito }}
                          </span>
                        </v-chip>
                        <v-chip color="cardbg" x-small text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
                          <span class="font-weight-medium fs-10">
                            {{ menudata.item.splitt }}
                          </span>
                        </v-chip>
                        <v-chip color="cardbg" x-small text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
                          <span class="font-weight-medium fs-10">
                            {{ menudata.item.splitr }}
                          </span>
                        </v-chip>
                      </v-chip-group>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                <v-progress-linear v-if="orderpoploader" indeterminate></v-progress-linear>

                <div :style="mf_tenure ? 'height:calc(100vh - 264px) !important;' : ''" :class="mf_tenure ? 'overflow-y-auto' : ''">
                  <div id="payment"></div>
                  <div v-if="!menudata.payment">
                    <div class="px-4 px-md-6 pt-4 pb-2">
                      <div v-if="mf_tenure != null && menudata.types != 'redem'">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Choose tenure</p>
                        <div class="d-inline-flex mb-4">
                          <span class="fs-14 font-weight-bold mt-2" :class="!mf_tenure ? 'maintext--text' : 'subtext--text'">Lumpsum</span>
                          <v-switch v-model="mf_tenure" :disabled="menudata && menudata.sipvalue == false ? true : false" hide-details class="mx-5 cust" inset></v-switch>
                          <span class="fs-14 font-weight-bold mt-2" :class="mf_tenure ? 'maintext--text' : 'subtext--text'">Monthly SIP</span>
                        </div>
                      </div>
                      <div v-if="menudata.types == 'redem'">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">
                          Redemption units
                          <span class="float-right"
                            >Total units : <b>{{ menudata.item.NET }}</b></span
                          >
                        </p>
                        <v-text-field
                          height="40px"
                          background-color="secbg"
                          flat
                          class="rounded-pill"
                          solo
                          type="number"
                          hide-details
                          hide-spin-buttons
                          v-model="mf_redqty"
                          @keyup="mf_redqty ? (mf_redqty = Number(mf_redqty)) : mf_redqty, (mf_invest_amt = (mf_redqty * menudata.item.NET_ASSET_VALUE).toFixed(2))"
                          :min="menudata.item.Minimum_Redemption_Qty"
                          :max="menudata.netqty"
                        >
                          <template #append>
                            <v-card v-if="menudata.item.NET - mf_redqty > 0" @click="mf_redqty = menudata.item.NET" class="font-weight-bold subtitle-2 primary--text elevation-0 crd-trn">
                              +
                              {{ menudata.item ? (menudata.item.NET - mf_redqty).toFixed(3) : 0 }}
                            </v-card>
                          </template>
                          <!--   <template #prepend-inner>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                              fill="none">
                              <path
                                d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
                                fill="white" />
                              <path
                                d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                                fill="#999999" />
                            </svg>
                          </div>
                        </template> -->
                        </v-text-field>
                        <p class="fs-10 subtext--text ml-6 mt-1">Min. redemption uints {{ menudata.item ? menudata.item.Minimum_Redemption_Qty : 0 }}</p>

                        <p class="font-weight-regular fs-14 subtext--text mb-2">Redemption amount</p>
                        <v-text-field
                          height="40px"
                          background-color="outline"
                          flat
                          class="rounded-pill"
                          solo
                          type="number"
                          hide-details
                          hide-spin-buttons
                          readonly
                          v-model="mf_invest_amt"
                          @keyup="mf_invest_amt ? (mf_invest_amt = Number(mf_invest_amt)) : mf_invest_amt, (mf_redqty = mf_invest_amt / menudata.item.NET_ASSET_VALUE)"
                          min="1"
                          :max="menudata.item.current"
                        >
                          <!-- <template #append>
                          <v-card @click="mf_invest_amt += menudata.item.Minimum_Purchase_Amount"
                            class="font-weight-bold subtitle-2 primary--text elevation-0 crd-trn"> +
                            {{ menudata.item.Minimum_Purchase_Amount }}
                          </v-card>
                        </template> -->

                          <template #prepend-inner>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="white" />
                              <path
                                d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                                fill="#999999"
                              />
                            </svg>
                          </template>
                        </v-text-field>
                        <p class="fs-10 subtext--text ml-6 mt-1">Max. redemption amount {{ menudata.item.NET_ASSET_VALUE ? menudata.item.NET_ASSET_VALUE : 0 }}</p>
                      </div>

                      <div v-else-if="mf_tenure">
                        <div v-if="menudata.types != 'redem'">
                          <p class="font-weight-regular fs-14 subtext--text mb-2">
                            Mandates
                            <v-tooltip top color="black">
                              <template v-slot:activator="{on, attrs}">
                                <span v-bind="attrs" v-on="on">
                                  <svg v-if="mf_mandate && mf_mandate.Status == 'APPROVED'" xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                                    <rect width="20" height="15" rx="7" fill="#2DB266" />
                                    <path d="M6.25 8.2475L8.415 10.4125L13.8275 5" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                  </svg>
                                  <svg v-else-if="mf_mandate && mf_mandate.Status" xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                                    <rect width="20" height="15" rx="7" fill="#DC2626" />
                                    <path d="M7.5 10L12.5 5M7.5 5L12.5 10" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                  </svg>
                                </span>
                              </template>
                              <span>{{ mf_mandate && mf_mandate.Status == "APPROVED" ? "Valid" : "Not valid" }}</span>
                            </v-tooltip>
                            <span v-if="mf_mandate && mf_mandate.MandateId" class="float-right"
                              >Amt: <b>{{ mf_mandate.Amount ? Number(mf_mandate.Amount).toFixed(2) : "0.00" }}</b></span
                            >
                          </p>
                          <div v-if="mf_mandates.length > 0">
                            <v-select v-model="mf_mandate" :disabled="!mf_mandates" :items="mf_mandates" append-icon="mdi-chevron-down" item-text="MandateId" item-value="MandateId" return-object background-color="secbg" flat class="rounded-pill mb-0" hide-details solo>
                              <template v-slot:item="{item, attrs, on}">
                                <v-list-item v-on="on" v-bind="attrs">
                                  <!-- //:disabled="item.Status != 'APPROVED'" -->
                                  <v-list-item-content>
                                    <v-list-item-title class="font-weight-medium"
                                      >{{ item.MandateId }}

                                      <v-tooltip top color="black">
                                        <template v-slot:activator="{on, attrs}">
                                          <span v-bind="attrs" v-on="on">
                                            <svg v-if="item.Status == 'APPROVED'" xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                                              <rect width="20" height="15" rx="7" fill="#2DB266" />
                                              <path d="M6.25 8.2475L8.415 10.4125L13.8275 5" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            <svg v-else-if="item.Status == 'REJECTED' || item.Status == 'INITIAL REJECTION' || item.Status.includes('REJECT')" xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                                              <rect width="20" height="15" rx="7" fill="#DC2626" />
                                              <path d="M7.5 10L12.5 5M7.5 5L12.5 10" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                                              <rect width="20" height="15" rx="7" fill="#FFB038" />
                                              <path d="M5.3125 8.75C6.00286 8.75 6.5625 8.19036 6.5625 7.5C6.5625 6.80964 6.00286 6.25 5.3125 6.25C4.62214 6.25 4.0625 6.80964 4.0625 7.5C4.0625 8.19036 4.62214 8.75 5.3125 8.75Z" fill="white" />
                                              <path d="M10.3125 8.75C11.0029 8.75 11.5625 8.19036 11.5625 7.5C11.5625 6.80964 11.0029 6.25 10.3125 6.25C9.62214 6.25 9.0625 6.80964 9.0625 7.5C9.0625 8.19036 9.62214 8.75 10.3125 8.75Z" fill="white" />
                                              <path d="M15.3125 8.75C16.0029 8.75 16.5625 8.19036 16.5625 7.5C16.5625 6.80964 16.0029 6.25 15.3125 6.25C14.6221 6.25 14.0625 6.80964 14.0625 7.5C14.0625 8.19036 14.6221 8.75 15.3125 8.75Z" fill="white" />
                                            </svg>
                                          </span>
                                        </template>
                                        <span>{{ item.Status }}</span>
                                      </v-tooltip>
                                      <span class="float-right">{{ Number(item.Amount).toFixed(2) }}</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="caption">reg date : {{ item.RegnDate }} </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-select>
                            <p class="text-right mb-2"><v-btn @click="(mf_tenure = null), setDefaultmandate()" color="primary" small class="text-none font-weight-bold" text plain>+ Create mandate</v-btn></p>
                          </div>
                          <v-btn v-else @click="mf_tenure = null" :loading="orderpoploader" color="btnclr" class="text-none rounded-pill elevation-0 btntext--text mb-4" block height="48px">+ Create mandate </v-btn>
                        </div>
                        <div :class="mf_sipinit ? 'mb-2' : 'mb-4'">
                          <v-checkbox color="maintext" v-model="mf_sipinit" hide-details>
                            <template #label>
                              <p class="font-weight-regular fs-14 subtext--text mb-0">Pay initial investment now</p>
                            </template>
                          </v-checkbox>
                        </div>
                        <!-- <p class="font-weight-regular fs-14 subtext--text mb-2">Initial investment</p> -->
                        <v-text-field
                          v-if="mf_sipinit"
                          height="40px"
                          background-color="secbg"
                          flat
                          class="rounded-pill mb-4"
                          solo
                          type="number"
                          hide-details
                          hide-spin-buttons
                          @keyup="mf_initial_amt ? (mf_initial_amt = Number(mf_initial_amt)) : mf_initial_amt >= menudata.item.Minimum_Purchase_Amount ? mf_initial_amt : menudata.item.Minimum_Purchase_Amount"
                          :min="menudata.item.Minimum_Purchase_Amount"
                          :step="menudata.item.Minimum_Purchase_Amount"
                          v-model="mf_initial_amt"
                        >
                          <template #append>
                            <v-card @click="mf_initial_amt += menudata.item.Minimum_Purchase_Amount" class="font-weight-bold subtitle-2 primary--text elevation-0 crd-trn">
                              +
                              {{ menudata.item.Minimum_Purchase_Amount }}
                            </v-card>
                          </template>

                          <template #prepend-inner>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="white" />
                              <path
                                d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                                fill="#999999"
                              />
                            </svg>
                          </template>
                        </v-text-field>

                        <p class="font-weight-regular fs-14 subtext--text mb-2">Instalment amount</p>
                        <v-text-field
                          v-if="mf_frequency"
                          height="40px"
                          background-color="secbg"
                          flat
                          class="rounded-pill"
                          solo
                          type="number"
                          hide-details
                          hide-spin-buttons
                          @keyup="mf_instal_amt ? (mf_instal_amt = Number(mf_instal_amt)) : mf_instal_amt >= menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_AMOUNT ? mf_instal_amt : menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_AMOUNT"
                          :min="menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_AMOUNT"
                          :max="menudata.sipvalue[mf_frequency].SIP_MAXIMUM_INSTALLMENT_AMOUNT"
                          :step="menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_AMOUNT"
                          v-model="mf_instal_amt"
                        >
                          <template #append>
                            <v-card @click="mf_instal_amt += menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_AMOUNT" class="font-weight-bold subtitle-2 primary--text elevation-0 crd-trn">
                              +
                              {{ menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_AMOUNT }}
                            </v-card>
                          </template>

                          <template #prepend-inner>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="white" />
                              <path
                                d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                                fill="#999999"
                              />
                            </svg>
                          </template>
                        </v-text-field>
                        <p class="fs-10 subtext--text ml-6 mt-1">Min. ₹{{ menudata.sipvalue && mf_frequency ? menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_AMOUNT : 0.0 }} (multiple of 1.0)</p>

                        <v-row class="pb-4">
                          <v-col cols="8">
                            <p class="font-weight-regular fs-14 subtext--text mb-2">Frequency</p>
                            <v-select
                              v-if="mf_frequency"
                              v-model="mf_frequency"
                              oninput="this.mf_frequency = mf_frequency.toUppercase()"
                              hide-details
                              :items="menudata.mf_frequencys"
                              append-icon="mdi-chevron-down"
                              background-color="secbg"
                              flat
                              class="rounded-pill"
                              solo
                              @change="(mf_instal_amt = menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_AMOUNT), (mf_noof_instal = menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_NUMBERS)"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="4" class="pl-0">
                            <p class="font-weight-regular fs-14 subtext--text mb-2">Date</p>
                            <v-select
                              v-if="mf_frequency"
                              :disabled="menudata.sipvalue[mf_frequency].mf_dates.length <= 1"
                              v-model="menudata.sipvalue[mf_frequency].mf_date"
                              hide-details
                              :items="menudata.sipvalue[mf_frequency].mf_dates"
                              append-icon="mdi-chevron-down"
                              background-color="secbg"
                              flat
                              class="rounded-pill"
                              solo
                            ></v-select>

                            <!-- <v-menu v-model="mf_dates" :close-on-content-click="false" transition="scale-transition"
                            offset-y max-width="290px" min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field background-color="secbg" flat class="rounded-pill" solo
                                v-model="mf_dateformatted" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker
                              :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                              :max="new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()).toISOString().slice(0, 10)"
                              v-model="mf_date" no-title @input="mf_dates = false"></v-date-picker>
                          </v-menu> -->
                          </v-col>
                        </v-row>

                        <p class="font-weight-regular fs-14 subtext--text mb-2">
                          Investment duration <span class="font-weight-bold txt-FF1 float-right">{{ mf_noof_instal }} {{ menudata.sipvalue && mf_frequency ? menudata.sipvalue[mf_frequency].freqis : "" }}</span>
                        </p>
                        <v-text-field
                          v-if="mf_frequency"
                          height="40px"
                          background-color="secbg"
                          flat
                          class="rounded-pill mb-2"
                          solo
                          type="number"
                          hide-details
                          hide-spin-buttons
                          :max="menudata.sipvalue[mf_frequency].SIP_MAXIMUM_INSTALLMENT_NUMBERS"
                          :min="menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_NUMBERS"
                          :step="menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_NUMBERS"
                          v-model="mf_noof_instal"
                        >
                        </v-text-field>
                        <p class="fs-10 subtext--text ml-6 mt-1">Min. duration {{ menudata.sipvalue && mf_frequency ? menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_NUMBERS : 0 }} {{ menudata.sipvalue && mf_frequency ? menudata.sipvalue[mf_frequency].freqis : "" }}</p>
                        <!-- <v-slider color="#FF1717" :step="menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_NUMBERS" v-model="mf_noof_instal" :max="menudata.sipvalue[mf_frequency].SIP_MAXIMUM_INSTALLMENT_NUMBERS > 360 ? 360 : menudata.sipvalue[mf_frequency].SIP_MAXIMUM_INSTALLMENT_NUMBERS/12" :min="menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_NUMBERS" hide-details
                        class="align-center">
                      </v-slider>
                      <p class="font-weight-medium fs-12 maintext--text mb-4">{{ menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_NUMBERS }} {{ menudata.sipvalue[mf_frequency].freqis }} <span class="float-right">{{ menudata.sipvalue[mf_frequency].SIP_MAXIMUM_INSTALLMENT_NUMBERS > 360 ? 360 : menudata.sipvalue[mf_frequency].SIP_MAXIMUM_INSTALLMENT_NUMBERS }} {{ menudata.sipvalue[mf_frequency].freqis }}</span> 
                      </p> -->
                      </div>

                      <div v-else-if="mf_tenure == false">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Investment amount</p>
                        <v-text-field
                          height="40px"
                          background-color="secbg"
                          flat
                          class="rounded-pill"
                          solo
                          type="number"
                          hide-details
                          hide-spin-buttons
                          @keyup="mf_invest_amt ? (mf_invest_amt = Number(mf_invest_amt)) : mf_invest_amt >= menudata.item.Minimum_Purchase_Amount ? mf_invest_amt : menudata.item.Minimum_Purchase_Amount"
                          :min="menudata.item.Minimum_Purchase_Amount"
                          :step="menudata.item.Minimum_Purchase_Amount"
                          v-model="mf_invest_amt"
                        >
                          <template #append>
                            <v-card @click="mf_invest_amt += menudata.item.Minimum_Purchase_Amount" class="font-weight-bold subtitle-2 primary--text elevation-0 crd-trn">
                              +
                              {{ menudata.item.Minimum_Purchase_Amount }}
                            </v-card>
                          </template>

                          <template #prepend-inner>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="white" />
                              <path
                                d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                                fill="#999999"
                              />
                            </svg>
                          </template>
                        </v-text-field>
                        <p class="fs-10 subtext--text ml-6 mt-1">Min. ₹{{ menudata.item ? menudata.item.Minimum_Purchase_Amount : 0.0 }} (multiple of 1.0)</p>
                      </div>
                      <div v-else-if="mf_tenure == null">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Amount *</p>
                        <v-text-field
                          :readonly="orderpoploader"
                          height="40px"
                          background-color="secbg"
                          flat
                          class="rounded-pill mb-4"
                          solo
                          type="number"
                          hide-details
                          hide-spin-buttons
                          @keyup="creatManmfamt ? (creatManmfamt = Number(creatManmfamt)) : creatManmfamt >= 100 ? mf_initial_amt : 100"
                          :min="100"
                          :step="1000"
                          v-model="creatManmfamt"
                        >
                          <template #append>
                            <v-card @click="creatManmfamt += 1000" class="font-weight-bold subtitle-2 primary--text elevation-0 crd-trn">
                              +
                              {{ 1000 }}
                            </v-card>
                          </template>

                          <template #prepend-inner>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="white" />
                              <path
                                d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                                fill="#999999"
                              />
                            </svg>
                          </template>
                        </v-text-field>

                        <v-row no-glutters class="pb-4">
                          <v-col cols="6">
                            <p class="font-weight-regular fs-14 subtext--text mb-2">Start date *</p>

                            <v-menu v-model="creatManmffrommenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                              <template v-slot:activator="{on, attrs}">
                                <v-text-field background-color="secbg" flat class="rounded-pill" solo hide-details v-model="creatManmffrom" readonly v-bind="attrs" v-on="on"></v-text-field>
                              </template>
                              <v-date-picker
                                :readonly="orderpoploader"
                                :min="new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 2).toISOString().slice(0, 10)"
                                v-model="creatManmffrommodel"
                                no-title
                                @input="(creatManmffrommenu = false), (creatManmftomodel = new Date(new Date().getFullYear() + 30, new Date(creatManmffrommodel).getMonth(), new Date().getDate()).toISOString().slice(0, 10))"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="6">
                            <p class="font-weight-regular fs-14 subtext--text mb-2">End date *</p>
                            <v-menu v-model="creatManmftomenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                              <template v-slot:activator="{on, attrs}">
                                <v-text-field background-color="secbg" flat class="rounded-pill" solo hide-details v-model="creatManmfto" readonly v-bind="attrs" v-on="on"></v-text-field>
                              </template>
                              <v-date-picker
                                :readonly="orderpoploader"
                                :min="creatManmffrommodel ? new Date(new Date().getFullYear(), new Date(creatManmffrommodel).getMonth() + 2, new Date().getDate()).toISOString().slice(0, 10) : new Date()"
                                v-model="creatManmftomodel"
                                no-title
                                @input="creatManmftomenu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>

                        <p class="font-weight-regular fs-14 subtext--text mb-2">Remarks</p>
                        <v-text-field :readonly="orderpoploader" height="40px" background-color="secbg" flat class="rounded-pill mb-8" solo type="text" hide-details hide-spin-buttons v-model="creatManmRemarks"> </v-text-field>

                        <v-row no-glutters>
                          <v-col cols="6" class="pb-1 pr-2">
                            <v-btn :disabled="orderpoploader" @click="mf_tenure = true" color="secbg" class="text-none rounded-pill elevation-0 subtext--text" height="40px" block> Cancel </v-btn>
                          </v-col>
                          <v-col cols="6" class="pb-1 pl-2">
                            <v-btn @click="setCreatMandate()" :disabled="!creatManmffrommodel || !creatManmftomodel || creatManmfamt < 100" :loading="orderpoploader" color="btnclr" class="text-none rounded-pill elevation-0 btntext--text" block height="40px">Submit </v-btn>
                          </v-col>
                        </v-row>
                      </div>

                      <!-- <p v-if="mf_tenure" class="font-weight-regular fs-12 subtext--text"><v-icon size="16"
                        color="#666">mdi-information-outline</v-icon> Pause/resume/step-up functions will not be available
                      for an AMC SIP</p> -->

                      <div v-if="mf_tenure != null && menudata.types != 'redem'">
                        <p class="font-weight-regular fs-14 subtext--text mb-2">Payment method</p>
                        <v-select v-model="mfpayinmode" hide-details :items="mfpayinmodes" item-text="val" item-value="key" append-icon="mdi-chevron-down" background-color="secbg" flat class="rounded-pill mb-3" placeholder="UPI, Net banking" solo> </v-select>

                        <p class="font-weight-regular fs-14 subtext--text mb-2">bank account</p>
                        <v-select v-model="mf_accact" hide-details :items="menudata.mf_bankaccs" return-object item-text="Bank_Name" item-value="Bank_AcNo" append-icon="mdi-chevron-down" background-color="secbg" flat class="rounded-pill mb-4" placeholder="bank" solo>
                          <template v-slot:item="{item, attrs, on}">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-content class="py-1">
                                <v-list-item-title class="font-weight-bold fs-14"
                                  >{{ item.Bank_Name }}
                                  <!-- <span class="float-right">{{ Number(item.Amount).toFixed(2) }}</span> -->
                                </v-list-item-title>
                                <v-list-item-subtitle class="caption">XXXX XXXX {{ item.Bank_AcNo.slice(-4) }} </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>

                        <v-form v-if="mfpayinmode == 'UPI'" class="" ref="mfupiform" v-model="mfupivalid" @submit.prevent="setmfUpiValid()" lazy-validation>
                          <p class="font-weight-regular fs-14 subtext--text mb-2">UPI ID (Virtual payment address)</p>

                          <v-text-field
                            :disabled="orderpoploader"
                            required
                            height="40px"
                            dense
                            background-color="secbg"
                            flat
                            class="rounded-pill"
                            solo
                            v-model="mfpainids"
                            @keyup="upiidfield = false"
                            :rules="[(v) => !!v || 'Upi Id is required', (v) => /.+@.+/.test(v) || 'Upi Id must be valid']"
                            placeholder="Add UPI ID"
                            :error-messages="upiIDerrors"
                          >
                          </v-text-field>
                        </v-form>

                        <p v-if="mf_tenure != null && menudata.types != 'redem'" class="font-weight-regular fs-12 subtext--text mb-0"><v-icon size="16" color="#666">mdi-information-outline</v-icon> NAV will be allotted on the day funds are realised at the clearing corporation.</p>
                      </div>
                    </div>
                  </div>
                </div>

                <v-toolbar v-if="!menudata.payment && mf_tenure != null" class="tool-sty elevation-0 pt-4 mb-2 px-4 px-md-6 crd-trn" dense>
                  <span v-if="menudata.types != 'redem'" class="font-weight-regular fs-10 subtext--text d-none d-md-block">
                    AUM <span class="primary--text font-weight-bold">{{ (menudata.item.AUM / 10000000).toFixed(2) }}</span> Cr. <br />
                    NAV <span class="primary--text font-weight-bold">₹{{ Number(menudata.item.NET_ASSET_VALUE).toFixed(2) }}</span>
                  </span>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="orderpoploader" @click="closeMenudialog('mforder')" color="secbg" class="text-none rounded-pill elevation-0 subtext--text px-6" height="40px"> Cancel </v-btn>
                  <v-btn
                    @click="setmfUpiValid()"
                    :disabled="
                      menudata.types == 'redem'
                        ? menudata.item.Minimum_Redemption_Qty > mf_redqty || mf_redqty > menudata.netqty
                        : (mf_tenure
                            ? (mf_frequency && Number(mf_noof_instal) < menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_NUMBERS) ||
                              (mf_frequency && Number(mf_instal_amt) < menudata.sipvalue[mf_frequency].SIP_MINIMUM_INSTALLMENT_AMOUNT) ||
                              Number(mf_initial_amt) < menudata.item.Minimum_Purchase_Amount ||
                              (mf_mandate && mf_mandate.Status != 'APPROVED')
                            : Number(mf_invest_amt) < menudata.item.Minimum_Purchase_Amount) ||
                          mf_accact == null ||
                          mfpayinmode == null ||
                          (mfpayinmode == 'UPI' && upiidfield && mfpainids != null)
                    "
                    :loading="orderpoploader"
                    color="btnclr"
                    class="text-none rounded-pill elevation-0 btntext--text px-6 ml-4"
                    height="40px"
                  >
                    {{ menudata.types == "redem" ? "Redeem" : mf_tenure ? "Pay now" : "Buy now" }}
                  </v-btn>
                  <!-- {{}} -->
                </v-toolbar>
              </v-card>
            </v-dialog>
            <v-dialog v-if="menudata.type >= 0" v-model="bondorderdialog" persistent @click:outside="!orderpoploader ? closeMenudialog('bondorder') : ''" width="400">
              <v-card class="pb-5 overflow-hidden" color="cardbg">
                <v-card class="elevation-0 pt-2 pb-1" color="secbg">
                  <v-toolbar class="elevation-0 px-1 px-md-2 crd-trn" dense>
                    <P class="font-weight-bold fs-16 maintext--text mb-0">Order {{ menudata.type == 0 ? "G-SEC" : menudata.type == 1 ? "T-BILL" : menudata.type == 2 ? "SDL" : menudata.type == 3 ? "SGB" : "" }} </P>

                    <v-spacer></v-spacer>
                    <v-btn :disabled="orderpoploader" @click="closeMenudialog('bondorder')" color="maintext" x-small fab outlined>
                      <v-icon color="maintext">mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                </v-card>
                <v-progress-linear v-if="orderpoploader" indeterminate></v-progress-linear>
                <div class="px-4 px-md-6 pt-4 pb-2">
                  <v-list-item class="px-0">
                    <v-list-item-content class="py-0">
                      <v-list-item-title class="font-weight-bold fs-16 maintext--text mb-0">{{ menudata.name ? menudata.name : "" }} </v-list-item-title>
                      <v-chip-group column class="mb-1 pt-0 mt-0">
                        <v-chip color="secbg" x-small text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
                          <span class="font-weight-medium fs-10">
                            {{ menudata.symbol ? menudata.symbol : "" }}
                          </span>
                        </v-chip>
                        <v-chip color="secbg" x-small text-color="subtext" style="border-radius: 5px; padding: 10px 8px !important">
                          <span class="font-weight-medium fs-10">
                            {{ menudata.isin ? menudata.isin : "" }}
                          </span>
                        </v-chip>
                      </v-chip-group>
                    </v-list-item-content>
                  </v-list-item>
                  <p class="font-weight-regular fs-14 subtext--text mb-2">Units</p>
                  <v-text-field
                    height="40px"
                    dense
                    background-color="secbg"
                    flat
                    @keyup="bondqty ? (bondqty = Number(bondqty)) : bondqty >= menudata.minbidqty ? bondqty : menudata.minbidqty"
                    class="rounded-pill"
                    solo
                    type="number"
                    hide-spin-buttons
                    :min="menudata.minbidqty"
                    hide-details
                    :step="menudata.lotbitsize"
                    :max="menudata.maxbidqty"
                    v-model="bondqty"
                  >
                    <template #append>
                      <v-btn @click="bondqty = bondqty + menudata.lotbitsize" icon class="elevation-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <circle cx="12" cy="12" r="12" fill="white" />
                          <path d="M12 8V16" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                          <path d="M16 12L8 12" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                        </svg>
                      </v-btn>
                    </template>

                    <template #prepend-inner>
                      <v-btn @click="bondqty == menudata.lotbitsize ? bondqty : (bondqty = bondqty - menudata.lotbitsize)" icon class="elevation-0">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <circle cx="12" cy="12" r="12" fill="white" />
                          <path d="M16 12L8 12" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                        </svg>
                      </v-btn>
                    </template>
                  </v-text-field>
                  <p class="fs-10 subtext--text ml-6 mt-2">Units limit {{ menudata.minbidqty }} - {{ menudata.maxbidqty }}</p>

                  <p class="subtext--text fs-12 mt-1 mb-0">
                    Ledger balance : <b>₹{{ menudata.ledger ? menudata.ledger : "0.00" }}</b>
                  </p>

                  <v-card v-if="menudata.ledger < menudata.cutoffPrice * bondqty" color="primhover" class="rounded-lg px-4 py-2 elevation-0 mt-4">
                    <p class="mb-0 primary--text fs-12">
                      <v-icon size="16" color="primary">mdi-information-outline</v-icon> Insufficient balance, Add fund ₹{{ menudata.cutoffPrice * bondqty }}
                      <v-btn @click="closeMenudialog('bondorder')" text class="text-none font-weight-black px-0" small color="primary" to="/funds">Click here</v-btn>
                    </p>
                  </v-card>
                </div>

                <v-toolbar class="tool-sty elevation-0 pt-4 mb-2 px-4 px-md-6 crd-trn" dense>
                  <span class="font-weight-regular fs-12 subtext--text">
                    Price : <span class="primary--text font-weight-bold"> ₹{{ menudata.cutoffPrice ? menudata.cutoffPrice.toFixed(2) : "" }}</span>
                  </span>
                  <v-spacer></v-spacer>

                  <v-btn :disabled="bondqty < menudata.minbidqty || menudata.ledger < menudata.cutoffPrice * bondqty" @click="setBondorder()" :loading="orderpoploader" color="btnclr" class="text-none rounded-pill elevation-0 btntext--text px-6 ml-4" height="40px"
                    >Invest ₹{{ menudata.cutoffPrice * bondqty }}
                  </v-btn>
                </v-toolbar>
              </v-card>
            </v-dialog>
            <v-dialog v-if="menudata.flow >= 0 || menudata.flow == 'mod'" v-model="iposorderdialog" persistent @click:outside="!orderpoploader ? closeMenudialog('iposorder') : ''" width="540">
              <v-card class="pb-4 pt-2 overflow-hidden" color="cardbg">
                <v-toolbar class="elevation-0 px-1 px-md-2 crd-trn" dense>
                  <P class="font-weight-bold fs-16 maintext--text mb-0">IPO Application</P>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="orderpoploader" @click="closeMenudialog('iposorder')" color="maintext" x-small fab outlined>
                    <v-icon color="maintext">mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-progress-linear v-if="orderpoploader" indeterminate></v-progress-linear>
                <div v-else class="pt-1"></div>

                <v-card class="elevation-0 rounded-t-lg rounded-b-0 pt-3 mx-3 mx-md-6 px-4" color="secbg">
                  <P class="fs-13 font-weight-bold mb-0 maintext--text table-hov-text mb-0">
                    {{ menudata.name ? menudata.name : "" }}
                    <v-chip color="#fff" x-small class="ml-2" text-color="#666" style="border-radius: 5px; padding: 10px 8px !important">
                      <span class="font-weight-medium fs-10">
                        {{ menudata.symbol }}
                      </span>
                    </v-chip>
                    <v-chip v-if="menudata.flow >= 0" color="#fff" x-small class="ml-2" text-color="#666" style="border-radius: 5px; padding: 10px 8px !important">
                      <span class="font-weight-medium fs-10">
                        {{ menudata.flow == 0 ? "IPO" : "SME" }}
                      </span>
                    </v-chip>
                  </P>

                  <v-list-item class="px-0 pt-3">
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium fs-14 maintext--text mb-0">{{ menudata.minBidQuantity ? menudata.minBidQuantity : "" }}</v-list-item-title>
                      <v-list-item-subtitle class="fs-12 subtext--text">Min. quantity</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium fs-14 maintext--text mb-0">₹ {{ menudata.minPrice ? menudata.minPrice : "0.00" }} - ₹ {{ menudata.maxPrice ? menudata.maxPrice : "0.00" }}</v-list-item-title>
                      <v-list-item-subtitle class="fs-12 subtext--text">Price range</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium fs-14 maintext--text mb-0"> {{ menudata.issueSize ? (menudata.issueSize / 10000000).toFixed(2) : "" }} Cr</v-list-item-title>
                      <v-list-item-subtitle class="fs-12 subtext--text">IPO Size</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                <v-card class="elevation-0 rounded-b-lg rounded-t-0 py-2 mx-3 mx-md-6 px-4" color="#FCEFD4">
                  <p class="subtext--text fs-12 mb-0">
                    IPO window is open till <b>{{ menudata.biddingEndDate ? menudata.biddingEndDate.slice(0, 11) : "" }} {{ menudata.dailyStartTime ? menudata.dailyEndTime.slice(0, 5) : "" }}.</b>
                  </p>
                </v-card>
                <div v-if="iposbids.length > 0" id="bodytab" class="px-4 px-md-6 pt-4 pb-2 overflow-y-auto" style="height: calc(100vh - 346px) !important" @scroll.passive="handleScroll">
                  <div v-if="menudata.category && menudata.category.val != 'modify'">
                    <p class="font-weight-medium fs-14 maintext--text mb-2">Category</p>
                    <!-- <v-select v-model="menudata.category" :items="menudata.categorys" append-icon="mdi-chevron-down" item-text="val" item-value="key" return-object ></v-select> -->
                    <v-autocomplete @change="ipoCalcs('cate')" return-object item-text="val" item-value="key" v-model="menudata.category" :items="menudata.categorys" append-icon="mdi-chevron-down" background-color="secbg" flat class="rounded-pill mb-4" hide-details solo></v-autocomplete>
                  </div>
                  <div v-for="(b, i) in iposbids" :key="i">
                    <div v-if="b.bitis">
                      <p class="maintext--text fs-12 fs-12 font-weight-medium mb-2">
                        {{ iposbids[i].name }}
                        <v-btn v-if="menudata.flow >= 0 && ((iposbids[2].bitis && iposbids[1].bitis && i == 2) || (!iposbids[2].bitis && iposbids[1].bitis && i == 1))" @click="iposbids[i].bitis = false" color="mainred" class="text-none font-weight-bold" text plain small :ripple="true">Delete</v-btn>
                      </p>
                      <v-row no-glutters>
                        <v-col cols="9">
                          <v-row no-glutters>
                            <v-col cols="6">
                              <p class="font-weight-medium fs-14 maintext--text mb-2">No.of Qty</p>
                              <v-text-field
                                :disabled="orderpoploader"
                                height="40px"
                                dense
                                background-color="secbg"
                                flat
                                @keyup="iposbids[i].qty ? (iposbids[i].qty = Number(iposbids[i].qty)) : iposbids[i].qty >= menudata.minBidQuantity ? iposbids[i].qty : menudata.minBidQuantity, ipoQtymultiper(i)"
                                class="rounded-pill"
                                solo
                                type="number"
                                hide-spin-buttons
                                :min="menudata.minBidQuantity"
                                hide-details
                                :step="menudata.lotSize"
                                :max="menudata.maxbidqty"
                                v-model="iposbids[i].qty"
                              >
                                <template #append>
                                  <v-btn :disabled="orderpoploader" @click="(iposbids[i].qty = iposbids[i].qty + menudata.lotSize), ipoCalcs()" icon class="elevation-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <circle cx="12" cy="12" r="12" fill="white" />
                                      <path d="M12 8V16" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                                      <path d="M16 12L8 12" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                  </v-btn>
                                </template>

                                <template #prepend-inner>
                                  <v-btn :disabled="iposbids[i].qty <= menudata.lotSize || orderpoploader" @click="iposbids[i].qty == menudata.lotSize ? iposbids[i].qty : (iposbids[i].qty = iposbids[i].qty - menudata.lotSize), ipoCalcs()" icon class="elevation-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <circle cx="12" cy="12" r="12" fill="white" />
                                      <path d="M16 12L8 12" stroke="#999999" stroke-width="2" stroke-linecap="round" />
                                    </svg>
                                  </v-btn>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <p class="font-weight-medium fs-14 maintext--text mb-2">Bid Price</p>
                              <v-text-field
                                dense
                                height="40px"
                                background-color="secbg"
                                flat
                                class="rounded-pill"
                                solo
                                type="number"
                                hide-details
                                hide-spin-buttons
                                :disabled="iposbids[i].cutoff || orderpoploader"
                                @keyup="iposbids[i].price ? (iposbids[i].price = Number(iposbids[i].price)) : iposbids[i].price >= menudata.minPrice ? iposbids[i].price : menudata.minPrice, ipoCalcs()"
                                :min="menudata.minPrice"
                                :max="menudata.maxPrice"
                                v-model="iposbids[i].price"
                              >
                                <template #prepend-inner>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="white" />
                                    <path
                                      d="M15.4285 9.25426L15.0805 10.4574H8.58762L8.93066 9.25426H15.4285ZM12.3014 17L8.71689 12.6399L8.70197 11.7102H10.4818C10.9326 11.7102 11.3137 11.6423 11.6253 11.5064C11.9401 11.3705 12.1788 11.1716 12.3412 10.9098C12.5069 10.648 12.5898 10.3232 12.5898 9.93537C12.5898 9.36198 12.4191 8.90956 12.0777 8.57812C11.7363 8.24337 11.2043 8.07599 10.4818 8.07599H8.58762L8.94061 6.81818H10.4818C11.3005 6.81818 11.9733 6.95076 12.5003 7.21591C13.0306 7.47775 13.425 7.84067 13.6835 8.30469C13.942 8.7687 14.0713 9.29901 14.0713 9.8956C14.0713 10.4325 13.9603 10.9214 13.7382 11.3622C13.5194 11.7997 13.1698 12.156 12.6892 12.4311C12.2086 12.7029 11.5756 12.8537 10.79 12.8835L10.7453 12.8935L14.0365 16.9155V17H12.3014ZM15.4285 6.81818L15.0805 8.03125L9.91504 7.99645L10.2631 6.81818H15.4285Z"
                                      fill="#999999"
                                    />
                                  </svg>
                                </template>
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="3" class="mt-auto">
                          <v-checkbox :disabled="orderpoploader" color="maintext" v-model="iposbids[i].cutoff" @click="iposbids[i].cutoff ? (iposbids[i].price = menudata.cutOffPrice) : (iposbids[i].price = menudata.minPrice), ipoCalcs()" class="mb-2" hide-details>
                            <template #label>
                              <p class="font-weight-regular fs-12 subtext--text mb-0">Cut-off <span class="d-none d-sm-flex">price</span></p>
                            </template>
                          </v-checkbox>
                        </v-col>
                      </v-row>
                      <v-divider v-if="i == 0 ? (iposbids[1].bitis ? true : false) : i == 1 ? (iposbids[2].bitis ? true : false) : false" class="mb-3 mt-4"></v-divider>
                    </div>
                  </div>
                  <v-btn v-if="menudata.flow >= 0 && !iposbids[2].bitis" :disabled="orderpoploader" @click="iposbids[1].bitis ? (iposbids[2].bitis = true) : (iposbids[1].bitis = true), ipoCalcs()" text color="primary" class="text-none mt-3">+ Add another bid </v-btn>

                  <v-card v-if="menudata.iposmax" color="orange lighten-5" class="rounded-lg px-4 py-2 elevation-0 mt-3">
                    <p class="mb-0 warning--text fs-12">
                      <v-icon size="16" color="warning">mdi-information-outline</v-icon> Maximum investment upto<span class="text-none font-weight-bold" color="warning"> ₹ {{ menudata.flow == 1 ? "2,00,000" : "5,00,000" }}</span>
                      only
                    </p>
                  </v-card>

                  <v-card v-if="menudata.iposrange" color="orange lighten-5" class="rounded-lg px-4 py-2 elevation-0 mt-3">
                    <p class="mb-0 warning--text fs-12">
                      <v-icon size="16" color="warning">mdi-information-outline</v-icon> Your bit price ranges between <span class="text-none font-weight-bold" color="warning">₹ {{ menudata.minPrice ? menudata.minPrice : "0.00" }} - ₹ {{ menudata.maxPrice ? menudata.maxPrice : "0.00" }}</span>
                    </p>
                  </v-card>

                  <v-card v-if="menudata.iposminqty" color="orange lighten-5" class="rounded-lg px-4 py-2 elevation-0 mt-3">
                    <p class="mb-0 warning--text fs-12">
                      <v-icon size="16" color="warning">mdi-information-outline</v-icon> Your minimum bit quantity <span class="text-none font-weight-bold" color="warning"> {{ menudata.minBidQuantity ? menudata.minBidQuantity : "0" }}</span>
                    </p>
                  </v-card>

                  <v-form :class="menudata.flow >= 0 ? '' : 'pt-3'" ref="iposupiform" v-model="iposupivalid" @submit.prevent="setUpiValid()" lazy-validation>
                    <p class="font-weight-medium fs-14 maintext--text mb-2" :class="!iposbids[2].bitis ? 'mt-2' : 'mt-6'">UPI ID (Virtual payment address)</p>
                    <v-text-field
                      :disabled="orderpoploader"
                      required
                      height="40px"
                      dense
                      background-color="secbg"
                      flat
                      class="rounded-pill"
                      solo
                      v-model="iposupiid"
                      @keyup="upiidfield = false"
                      :rules="[(v) => !!v || 'Upi Id is required', (v) => /.+@.+/.test(v) || 'Upi Id must be valid']"
                      placeholder="Add UPI ID"
                      :error-messages="upiIDerrors"
                    >
                    </v-text-field>
                    <v-checkbox id="check" :disabled="menudata.iposmax || menudata.iposrange || menudata.iposminqty || orderpoploader" required :rules="[(v) => !!v || 'You must agree to invest!']" color="maintext" v-model="iposcheckbox" class="mt-0" hide-details>
                      <template #label>
                        <p class="font-weight-regular fs-12 subtext--text mb-0">I hereby undertake that I have read the Red Herring Prospectus and I am eligible bidder as per the applicable provisions of SEBI (Issue of Capital & Disclosure Agreement, 2009) regulations</p>
                      </template>
                    </v-checkbox>
                  </v-form>
                </div>
                <v-toolbar class="tool-sty elevation-0 pt-3 mb-2 px-4 px-md-6 crd-trn" dense>
                  <span class="font-weight-regular fs-12 subtext--text">
                    Total investment : <span class="primary--text font-weight-bold"> ₹{{ menudata.ipostotal ? menudata.ipostotal.toFixed(2) : "" }}</span>
                  </span>
                  <v-spacer></v-spacer>
                  <v-btn :disabled="orderpoploader" @click="closeMenudialog('iposorder')" color="secbg" class="text-none rounded-pill elevation-0 subtext--text px-6 d-none d-md-flex" height="40px"> Cancel </v-btn>
                  <v-btn @click="setUpiValid()" :disabled="!iposupivalid || menudata.iposmax || menudata.iposrange || menudata.iposminqty || upiidfield" type="submit" :loading="orderpoploader" color="btnclr" class="text-none rounded-pill elevation-0 btntext--text px-6 ml-4" height="40px"
                    >Continue
                  </v-btn>
                </v-toolbar>
              </v-card>
            </v-dialog>

            <v-dialog v-model="showalert" persistent hide-overlay width="380">
              <v-card class="mx-auto pa-4 pb-6 text-right rounded-xl" width="100%">
                <v-btn :disabled="orderloader" @click="(showalert = false), (showalertmsg = null)" color="maintext" x-small fab outlined>
                  <v-icon color="maintext">mdi-close</v-icon>
                </v-btn>
                <v-card-text class="text-left px-3">
                  <p class="title maintext--text font-weight-bold mb-2">
                    {{ showalertmsg ? showalertmsg.title : "" }}
                  </p>

                  <p class="fs-16 font-weight-medium mb-3">
                    <span v-if="showalertmsg && showalertmsg.body" v-html="showalertmsg.body"></span>
                  </p>
                </v-card-text>
                <v-btn v-if="showalertmsg && showalertmsg.callback" @click="showalertmsg.callback ? showAlertmode() : (showalert = false)" color="#FFECB3" class="text-none warning--text elevation-0 rounded-pill mr-2"> Ok </v-btn>
              </v-card>
            </v-dialog>

            <v-dialog v-model="riskdialog" persistent width="620">
              <v-card class="elevation-0 py-4 px-2 rounded-xl">
                <v-card-title class="title font-weight-bold mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 2.26953V6.40007C14 6.96012 14 7.24015 14.109 7.45406C14.2049 7.64222 14.3578 7.7952 14.546 7.89108C14.7599 8.00007 15.0399 8.00007 15.6 8.00007H19.7305M16 13H8M16 17H8M10 9H8M14 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.11984 22 8.8 22H15.2C16.8802 22 17.7202 22 18.362 21.673C18.9265 21.3854 19.3854 20.9265 19.673 20.362C20 19.7202 20 18.8802 20 17.2V8L14 2Z"
                      :stroke="$vuetify.theme.dark ? 'white' : 'black'"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span class="ml-3"> Risk disclosures on derivatives </span>
                </v-card-title>
                <v-divider class="mx-4"></v-divider>
                <v-card-text class="px-4 px-md-8 py-7 riskpopup">
                  <ul :class="$vuetify.theme.dark ? 'white--text' : 'black--text'" class="subtitle-1 mb-4">
                    <li style="line-height: 24px" class="mb-2">9 out of 10 individual traders in the equity Futures and Options (F&O) segment incurred net losses.</li>
                    <li style="line-height: 24px" class="mb-2">On average, the loss-making traders registered a net trading loss close to ₹50,000.</li>
                    <li style="line-height: 24px" class="mb-2">Over and above the net trading losses incurred, loss makers expended an additional 28% of net trading losses as transaction costs.</li>
                    <li style="line-height: 24px">Those making net trading profits incurred between 15% to 50% of such profits as transaction costs.</li>
                  </ul>

                  <p class="caption mb-0">
                    Source:
                    <a target="_blank" style="text-decoration: none; color: var(--primary)" href="https://www.sebi.gov.in/reports-and-statistics/research/jan-2023/study-analysis-of-profit-and-loss-of-individual-traders-dealing-in-equity-fando-segment_67525.html"> SEBI study</a> dated January 25,
                    2023, on "Analysis of Profit and Loss of Individual Traders dealing in equity Futures and Options (F&O) Segment," wherein Aggregate Level findings are based on annual Profit/Loss incurred by individual traders in equity F&O during FY 2021-22.
                  </p>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-actions class="mt-3">
                  <v-spacer></v-spacer>
                  <v-btn color="var(--primary)" dark class="elevation-0 text-none font-weight-bold rounded-pill px-4" @click="closeRisk()"> I Understand </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-container>

        <v-overlay :value="subloader" opacity="1" color="transparent" class="rounded-0">
          <div class="text-center">
            <v-card width="70%" class="elevation-0 mx-auto crd-trn">
              <v-progress-circular color="primary" :size="60" :width="4" indeterminate></v-progress-circular>
            </v-card>
          </div>
        </v-overlay>
      </div>
      <v-footer padless style="position: fixed; bottom: 0px; left: 0; width: 100%; z-index: 10" color="cardbg" class="pb-1 d-sm-none">
        <v-row no-glutters>
          <v-col @click="mobilemenu = true" cols="4" class="text-center pb-2">
            <v-divider :color="sidetab == 0 ? '#0037B7' : ''" class="pt-1 mb-2"></v-divider>
            <div>
              <v-card class="elevation-0" color="transparent" height="24px">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
                  <path
                    d="M16.459 10.9165C18.7372 10.9165 20.584 9.06968 20.584 6.7915C20.584 4.51333 18.7372 2.6665 16.459 2.6665C14.1808 2.6665 12.334 4.51333 12.334 6.7915C12.334 9.06968 14.1808 10.9165 16.459 10.9165Z"
                    :stroke="sidetab == 0 ? '#0037B7' : '#506D84'"
                    stroke-width="1.4"
                    stroke-miterlimit="10"
                    stroke-linecap="square"
                  />
                  <path d="M11.4167 12.75H5V19.1667H11.4167V12.75Z" :stroke="sidetab == 0 ? '#0037B7' : '#506D84'" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="square" />
                  <path d="M1.33398 8.16667L5.00065 1.75L8.66732 8.16667H1.33398Z" :stroke="sidetab == 0 ? '#0037B7' : '#506D84'" stroke-width="1.4" stroke-miterlimit="10" stroke-linecap="square" />
                </svg>
              </v-card>
              <!-- <p class="fs-12 font-weight-medium text-none mb-0 slider" :class="sidetab == 0 ? 'primary--text' : 'maintext--text'">
                {{ mdashitems[mtabs] }}
                <v-icon size="20" :color="sidetab == 0 ? '#0037B7' : '#506D84'"> mdi-chevron-down</v-icon>
              </p> -->
              <div class="slider-wrapper fs-12 font-weight-medium text-none">
                <div class="slider">
                  <div v-for="(a, l) in mdashitems" :key="l" :class="`slider-text-${l} ${apptab == l ? 'primary--text' : 'maintext--text'}`">
                    {{ a }}
                  </div>
                </div>
                <v-icon size="20" :color="sidetab == 0 ? '#0037B7' : '#506D84'"> mdi-chevron-down</v-icon>
              </div>
            </div>
          </v-col>
          <v-col @click="uid ? ($router.push(`/orders`), setTabway()) : goSso()" cols="4" class="text-center pb-2">
            <v-divider :color="sidetab == 1 ? '#0037B7' : ''" class="pt-1 mb-2"></v-divider>
            <div>
              <v-card class="elevation-0" color="transparent" height="24px">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.8" clip-path="url(#clip0_8033_1489)">
                    <path d="M1.66797 4.16406H18.3346" :stroke="sidetab == 1 ? '#0037B7' : '#506D84'" stroke-width="2" stroke-miterlimit="10" />
                    <path d="M18.3346 19.1654H1.66797V4.16536L5.0013 0.832031H15.0013L18.3346 4.16536V19.1654Z" :stroke="sidetab == 1 ? '#0037B7' : '#506D84'" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
                    <path d="M14.1693 8.33203C14.1693 10.6654 12.3359 12.4987 10.0026 12.4987C7.66927 12.4987 5.83594 10.6654 5.83594 8.33203" :stroke="sidetab == 1 ? '#0037B7' : '#506D84'" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
                  </g>
                  <defs>
                    <clipPath id="clip0_8033_1489">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </v-card>
              <p class="fs-12 font-weight-medium text-none mb-0" :class="sidetab == 1 ? 'primary--text' : 'maintext--text'">Orders</p>
            </div>
          </v-col>
          <v-col @click="uid ? ($router.push(`/funds`), setTabway()) : goSso()" cols="4" class="text-center pb-2">
            <v-divider :color="sidetab == 2 ? '#0037B7' : ''" class="pt-1 mb-2"></v-divider>
            <div>
              <v-card class="elevation-0" color="transparent" height="24px">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.8" clip-path="url(#clip0_8033_1494)">
                    <path d="M4.9987 2.5H2.4987C1.57786 2.5 0.832031 3.24583 0.832031 4.16667C0.832031 5.0875 1.57786 5.83333 2.4987 5.83333" :stroke="sidetab == 2 ? '#0037B7' : '#506D84'" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
                    <path d="M5 5.83203V0.832031H16.6667V5.83203" :stroke="sidetab == 2 ? '#0037B7' : '#506D84'" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
                    <path d="M19.1654 5.83073H2.4987C1.57786 5.83073 0.832031 5.0849 0.832031 4.16406V16.6641C0.832031 18.0449 1.9512 19.1641 3.33203 19.1641H19.1654V5.83073Z" :stroke="sidetab == 2 ? '#0037B7' : '#506D84'" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
                    <path
                      d="M14.1667 14.1654C15.0871 14.1654 15.8333 13.4192 15.8333 12.4987C15.8333 11.5782 15.0871 10.832 14.1667 10.832C13.2462 10.832 12.5 11.5782 12.5 12.4987C12.5 13.4192 13.2462 14.1654 14.1667 14.1654Z"
                      :stroke="sidetab == 2 ? '#0037B7' : '#506D84'"
                      stroke-width="2"
                      stroke-miterlimit="10"
                      stroke-linecap="square"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8033_1494">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </v-card>
              <p class="fs-12 font-weight-medium text-none mb-0" :class="sidetab == 2 ? 'primary--text' : 'maintext--text'">Funds</p>
            </div>
          </v-col>
        </v-row>
      </v-footer>
      <v-bottom-sheet v-model="mobilemenu" class="d-md-none">
        <v-sheet class="rounded-t-xl px-4" height="40vh">
          <div @click="mobilemenu = false" class="text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="3" viewBox="0 0 32 3" fill="none">
              <rect width="32" height="3" rx="1.5" fill="#DDDDDD" fill-opacity="0.866667" />
            </svg>
          </div>
          <v-list-item v-for="(s, d) in dashitems" :key="d" @click="sidetab <= 2 || s.idx != apptab ? ($router.push(s.path), (mobilemenu = false), setTabway()) : null">
            <v-list-item-content>
              <v-list-item-title :class="s.idx == apptab && sidetab == 0 ? 'font-weight-medium primary--text' : ''">{{ s.txt }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </div>
</template>

<script>
import VSnackbars from "v-snackbars";
import axios from "axios";
import AppBar from "../AppBar.vue";
import WatchList from "../../views/Watchlist/WatchList.vue";
import apiurl from "../../apiurl.js";
import eventBus from "../../eventBus.js";

import {mynturl} from "../../apiurl.js";

// import notifi from '../../assets/notification.mp3'
// getValidSession
// getMasters
import {getPlaceOrder, getGTTPlaceOrder, getOrderMargin, getQuotesdata, getSecuritydata, getBrokerage, getMFsipvalue, getMFmandate, getMFAddmandate, getMFBankdetails, getBondLedger, getFundsupis, getUpivpa, setMalert, getActiveSession} from "../../components/mixins/getAPIdata.js";
import Datafeed from "../../components/mixins/feedFactory.js";
// import TVChartContainer from '../TVChartContainer.vue'
// getKambalaAuth
export default {
  masterpic: [],

  data: () => ({
    bodytab: "Stocks",
    riskdialog: false,

    mobilemenu: false,
    dashitems: [
      {txt: "Stocks", path: "/stocks", idx: 0},
      // {txt: "F&O", path: "/fno", idx: 1},
      {txt: "Mutual Fund", path: "/mutualfund", idx: 1},
      {txt: "IPOs", path: "/ipo", idx: 2},
      {txt: "Bonds", path: "/bonds", idx: 3},
      {txt: "Collection", path: "/collection", idx: 4, type: "Beta"},
      // { txt: 'details', path: '/stocks/details', idx: 4 },
    ],

    mdashitems: [
      "Stocks",
      // "F&O",
      "Mutual Fund",
      "IPOs",
      "Bonds",
      "Collection",
    ],

    apptab: 0,
    sidetab: null,

    valid: true,
    mobile: "",
    numberis: [(v) => !!v || "Your mobile number is required", (v) => /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number"],

    uid: null,
    token: null,
    mtoken: null,

    snackbar: false,
    snackcolor: "#121212",
    snacktxt: "",
    subloader: false,
    mainloader: true,

    // stock order
    buyrsell: false,
    orderexch: "bse",
    investype: "I",
    prcitems: [
      {txt: "Limit", val: "LMT"},
      {txt: "Market", val: "MKT"},
      {txt: "SL Limit", val: "SL-LMT"},
      {txt: "SL Mkt", val: "SL-MKT"},
    ],
    prc_c_items: [
      {txt: "Limit", val: "LMT"},
      {txt: "Market", val: "MKT"},
      {txt: "SL Limit", val: "SL-LMT"},
    ],
    alertitems: ["LTP", "Perc. change", "ATP", "OI", "TOI", "52HIGH", "VOLUME"],
    alertis: "LTP",
    condition: ">",
    alertremarks: "",
    alertvalue: 0,

    orderloader: false,
    prc: "LMT",
    ocoprc: "LMT",
    mktprdordpec: 5,
    ordqty: 0,
    ordgttqty: 0,
    orddisqty: 0,
    ordlegqty: 0,
    ordprice: 0,
    ordgttprice: 0,
    ordtrgprice: 0,
    ordtagprice: 0,
    ordslprice: 0,
    ordgtttrgprice: 0,
    ordvalqtyprice: "DAY",
    addvalqty: false,
    addmktord: false,
    gttagree: false,
    orderselect: ["Regular", "Cover", "Bracket", "GTT", "SIP"],
    // 'AMO', 'Iceberg', 'GTT'
    ordertype: 3,

    gttBSM: "new",
    gttalertitemso: ["LTP", "Perc. change", "ATP", "OI", "TOI", "52HIGH", "VOLUME"],
    gttalertitemst: ["LTP", "Perc. change", "ATP", "OI", "TOI", "52LOW"],

    gttconitem: [">", "<"],
    gttcond: ">",
    gttocopanel: false,
    gttplacebtn: false,
    gttalert: "LTP",
    ocoalert: "LTP",
    gttvalue: 0,
    ocovalue: 0,
    gttremark: null,
    ocoremark: null,
    gttqty: 1,
    ocoqty: 1,
    gttprice: null,
    ocoprice: null,
    gtttrgprice: null,
    ocotrgprice: null,
    //

    // Mutual fund
    mf_mandates: [],
    mf_frequencys: [],
    mf_dates: false,
    mf_frequency: null,
    mf_mandate: "",
    mf_date: "",
    mf_invest_amt: 1000,
    mf_initial_amt: 1000,
    mf_instal_amt: 1000,
    mf_tenure: false,
    mf_sipinit: false,
    mf_noof_instal: 0,
    mf_redqty: 0,

    mfpayinmode: null,
    mfpayinmodes: [
      {key: "UPI", val: "UPI"},
      {key: "NET BANKING", val: "Net banking"},
      // {key: "NIFT", val: "NIFT"},
    ],
    mfpainids: null,
    mf_accact: null,
    mfupivalid: true,

    orderpoploader: false,

    creatManmfamt: 1000,
    creatManmffrommodel: "",
    creatManmffrommenu: false,

    creatManmftomodel: "",
    creatManmftomenu: false,
    creatManmRemarks: "",
    // creatManmffrom: '',
    // creatManmfto: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    //

    bondqty: null,

    showalertmsg: null,
    showalert: false,
    alertdialog: false,
    orderdialog: false,
    mforderdialog: false,
    bondorderdialog: false,
    iposorderdialog: false,

    chartitems: [{txt: "1M"}, {txt: "3M"}, {txt: "6M"}, {txt: "1YR"}, {txt: "3YR"}, {txt: "5YR"}, {txt: "MAX"}],
    chartdate: 0,
    Fundamentalsfield: [],

    iposbids: [],

    menudata: {},
    alertloader: false,
    iposupivalid: true,
    iposcheckbox: false,
    iposupiid: null,
    iposupiDomain: null,

    upiidfield: false,

    wsstocksdata: [],

    messages: [],
    wsorderalertdata: [],

    masteron: true,
    snackalerts: [],
  }),

  async created() {
    sessionStorage.removeItem("c3RhdHVz");
    var theme;
    let t = localStorage.getItem("web.theme");
    if (t == "default") {
      theme = window.matchMedia?.("(prefers-color-scheme: dark)").matches ? "dark" : "light";
      this.$vuetify.theme.dark = theme == "dark" ? true : false;
    } else {
      theme = t == "dark" || t == "light" ? t : "light";
      this.$vuetify.theme.dark = theme == "dark" ? true : false;
    }
    localStorage.setItem("web.theme", theme);
    document.getElementById("theme-link").setAttribute("href", `theme-${theme}.css`);
    this.mainloader = true;
    // this.subloader = true;

    var url = new URL(window.location.href);

    var actid = url.searchParams.get("uid");
    var token = url.searchParams.get("token");
    var sess = url.searchParams.get("sess");
    if (typeof actid == "string" && typeof token == "string") {
      sessionStorage.setItem("usession", token);
      sessionStorage.setItem("userid", actid);
      sessionStorage.setItem("msession", sess);
    }
    this.mtoken = sessionStorage.getItem("msession");
    this.token = sessionStorage.getItem("usession");
    this.uid = sessionStorage.getItem("userid");
    this.getuserSess(actid);

    var logid = this.uid + new Date().toLocaleDateString();
    let log = sessionStorage.getItem(logid);
    logid = JSON.parse(log);
    this.snackalerts = logid && logid.length > 0 ? logid : [];
    this.setTabway();
  },
  computed: {
    mf_dateformatted() {
      return this.formatDate(this.mf_date);
    },
    creatManmffrom() {
      return this.formatDate(this.creatManmffrommodel);
    },

    creatManmfto() {
      return this.formatDate(this.creatManmftomodel);
    },

    changefieldType(text) {
      return Number(text);
    },

    upiIDerrors() {
      const errors = [];
      if (this.upiidfield) {
        errors.push("You have entered the invalid UPI ID");
      }
      return errors;
    },
  },
  async mounted() {
    // this.setMenudialog('order', '1333', 'NSE', 'HDFCBANK-EQ', 'b');
    eventBus.$on("snack-event", (color, msg) => {
      this.snackAlert(color, msg);
    });
    eventBus.$on("menudialog", (type, token, exch, tsym, trantype, item) => {
      this.setMenudialog(type, token, exch, tsym, trantype, item);
    });
    eventBus.$on("login", () => {
      this.goSso();
    });
    eventBus.$on("show-alert", (params) => {
      this.showalert = true;
      this.showalertmsg = params;
    });
    eventBus.$on("ws-uo", (data) => {
      if (data != "attempt") {
        this.setWSOrderalert(data);
      } else {
        this.setWebsocket("sub", this.wsstocksdata.wl);
        this.setWebsocket("sub", this.wsstocksdata.pd);
        this.setWebsocket("sub", this.wsstocksdata.rawdata);
      }
      // this.showalert = true;
      // this.showalertmsg = params;
    });
    eventBus.$on("login-event", () => {
      // if (this.uid && this.token) {
      this.getuserSess();
      // }
    });
    eventBus.$on("bondmodify-event", (data) => {
      this.setBondorder(0, data);
    });

    this.wsstocksdata["wl"] = [];
    this.wsstocksdata["pd"] = [];
    this.wsstocksdata["tok"] = [];
    this.wsstocksdata["raw"] = null;
    this.wsstocksdata["rawdata"] = [];
    eventBus.$on("web-scoketOn", (flow, data, is, page) => {
      let wsdata = [];
      if (this.wsstocksdata.raw != null && this.wsstocksdata.raw != page && page != "watchlist") {
        if (this.wsstocksdata.rawdata && this.wsstocksdata.rawdata.length > 0 && is != "wl" && is != "pd") {
          for (let i = 0; i < this.wsstocksdata.tok.length; i++) {
            let p = this.wsstocksdata.rawdata.findIndex((o) => o.token == this.wsstocksdata.tok[i].token);
            if (p >= 0) {
              this.wsstocksdata.rawdata.splice(p, 1);
            }
          }
          this.setWebsocket("unsub", this.wsstocksdata.rawdata, "is");
          this.wsstocksdata.rawdata = [];
          this.wsstocksdata.raw = page;
        }
      } else if (page != "watchlist") {
        this.wsstocksdata.raw = page;

        if (flow == "unsub-D") {
          for (let i = 0; i < this.wsstocksdata.tok.length; i++) {
            let p = data.findIndex((o) => o.token == this.wsstocksdata.tok[i].token);
            if (p >= 0) {
              data.splice(p, 1);
            }
          }
          this.setWebsocket("unsub", data, "is");
        }
      }
      if (flow == "unsub" && page == "watchlist") {
        for (let k = 0; k < this.wsstocksdata.wl.length; k++) {
          let p = this.wsstocksdata.tok.findIndex((o) => o.token == this.wsstocksdata.wl[k].token);
          if (p >= 0) {
            this.wsstocksdata.tok.splice(p, 1);
          }
        }
        this.setWebsocket("unsub", this.wsstocksdata.wl, "is");
        this.wsstocksdata.wl = [];
      }
      if (flow == "sub") {
        for (let i = 0; i < data.length; i++) {
          var info = {exch: data[i].exch, token: data[i].token, tsym: data[i].tsym};
          wsdata.push(info);
          let p = this.wsstocksdata.rawdata.findIndex((o) => o.token == data[i].token);
          if (is == "wl" || is == "pd") {
            this.wsstocksdata[is].push(info);
            this.wsstocksdata.tok.push(info);
          }
          if (p == -1) {
            this.wsstocksdata.rawdata.push(info);
          }
        }
      }
      if (flow && wsdata) {
        this.setWebsocket(flow, wsdata, is);
      } else {
        eventBus.$emit("web-scoketConn");
      }
    });

    eventBus.$on("tabBar-load", () => {
      this.setTabway();
    });

    eventBus.$on("reload-load", () => {
      // this.setUnsubs();
    });

    eventBus.$on("color-event", (id, value, type) => {
      this.setColorstyle(id, value, type);
    });

    localStorage.removeItem("ssdtoken");
    localStorage.removeItem("ssdtsym");

    this.succSound = require("@/assets/sounds/succnote.mp3");
    this.failSound = require("@/assets/sounds/failnote.mp3");
    this.pendSound = require("@/assets/sounds/pennote.mp3");

    const self = this;
    document.onkeydown = function (evt) {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
        isEscape = evt.key === "Escape" || evt.key === "Esc";
      } else {
        isEscape = evt.keyCode === 27;
      }
      if (isEscape) {
        self.setEscape();
      }
    };
  },
  beforeDestroy() {
    eventBus.$off("snack-event");
    eventBus.$off("login");
    eventBus.$off("login-event");
    eventBus.$off("bondmodify-event");
    eventBus.$off("menudialog");
    eventBus.$off("web-scoketOn");
    eventBus.$off("tabBar-load");
  },
  methods: {
    setDragableSett() {
      setTimeout(() => {
        dragElement(document.getElementById("maindiv"));
        function dragElement(elmnt) {
          var pos1 = 0,
            pos2 = 0,
            pos3 = 0,
            pos4 = 0;
          if (document.getElementById(elmnt.id + "header")) {
            // if present, the header is where you move the DIV from:
            document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
          } else {
            // otherwise, move the DIV from anywhere inside the DIV:
            elmnt.onmousedown = dragMouseDown;
          }

          function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
          }

          function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            elmnt.style.top = elmnt.offsetTop - pos2 + "px";
            elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
          }

          function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
          }
        }
      }, 10);
    },
    setWSOrderalert(data) {
      this.playSound(data.status);
      var msg = `Your ${data.trantype == "S" ? "SELL" : "BUY"} order ${data.norenordno} for ${data.tsym} in ${data.exch} is ${data.status}`;
      this.wsorderalertdata.push({
        message: msg,
        timeout: 4000,
        color: data.status == "COMPLETE" ? "success" : data.status == "REJECTED" || data.status == "CANCELED" ? "error" : "warning",
      });

      if (data && data.status) {
        this.snackalerts.unshift({time: new Date().toLocaleTimeString("en-US"), msg: msg});
        var logid = this.uid + new Date().toLocaleDateString();
        sessionStorage.setItem(logid, JSON.stringify(this.snackalerts));
      }
    },
    async getuserSess(k) {
      this.subloader = true;
      if (this.uid) {
        let sessvalid = await getActiveSession(this.uid);

        if (sessvalid && sessvalid.stat == "Ok" && sessvalid.apitoken && sessvalid.token) {
          // export const mynturl = { myntapi: "https://go.mynt.in/NorenWClientTP/", webSocketURL: "wss://go.mynt.in/NorenWSWeb/", source: "API" }
          // mynturl["myntapi"] = "https://go.mynt.in/NorenWClientTP/";
          // mynturl["source"] = "API";
          mynturl["myntapi"] = sessvalid.url;
          mynturl["source"] = sessvalid.source;
          mynturl["webSocketURL"] = sessvalid.wss;
          mynturl["stat"] = 1;
          sessionStorage.setItem("c3RhdHVz", "dmFsaWR1c2Vy");
          // if (window.masterpic != "Ok" && this.masteron) {
          //   this.masteron = false;
          //   window.masterpic = await getMasters();
          // }
          if (k) {
            var url = new URL(window.location.href);
            this.$router.push(url.pathname);
            // window.location.assign(window.location.href.split('?')[0])
          }
          let path = window.location;

          let r = sessionStorage.getItem("risk");
          if (r != 0 && path.pathname.includes("stocks")) {
            this.riskdialog = true;
          }

          var ordpre = JSON.parse(localStorage.getItem(`${this.uid}_ordpref`));
          if (!(ordpre && ordpre.mainpreitems)) {
            var data = {
              mktpro: 5,
              qtypre: "0",
              mainpreitems: {NSE: ["CNC", "LMT", "DAY", ""], BSE: ["CNC", "LMT", "DAY", ""], MCX: ["NRML", "LMT", "DAY", ""], NFO: ["NRML", "LMT", "DAY", ""], CDS: ["NRML", "LMT", "DAY", ""], BFO: ["NRML", "LMT", "EOS", ""], BCD: ["NRML", "LMT", "EOS", ""]},
            };
            localStorage.setItem(`${this.uid}_ordpref`, JSON.stringify(data));
          }
        } else if (this.uid && this.token && sessvalid && sessvalid.emsg) {
          this.snackAlert(2, sessvalid.emsg ? sessvalid.emsg : sessvalid);
          eventBus.$emit("storage-reset");
          this.mtoken = null;
          this.token = null;
          this.uid = null;
        } else {
          eventBus.$emit("storage-reset");
          let path = window.location;
          if (this.token && this.uid && path.pathname != "/bonds" && path.pathname != "/stocks" && path.pathname != "/mutualfund" && path.pathname != "/mutualfund/categories" && path.pathname != "/ipo" && path.pathname != "/ipo/performance") {
            this.snackAlert(2, "Error: Request failed with status code 401");
            setTimeout(() => {
              this.goSso();
            }, 2000);
          }
        }
      }

      setTimeout(() => {
        eventBus.$emit("app-user-event");
        eventBus.$emit("user-event");
      }, 10);
      this.mainloader = false;
      this.subloader = false;
    },
    setTabway() {
      let path = window.location;
      if (path.pathname.includes("stocks")) {
        this.bodytab = "Stocks";
        this.apptab = 0;
      } else if (path.pathname.includes("mutualfund")) {
        this.bodytab = "Mutual funds";
        this.apptab = 1;
      } else if (path.pathname.includes("ipo")) {
        this.bodytab = "IPOs";
        this.apptab = 2;
      } else if (path.pathname.includes("bonds")) {
        this.bodytab = "Bonds";
        this.apptab = 3;
      } else if (path.pathname.includes("collection")) {
        this.bodytab = "Collection";
        this.apptab = 4;
      }

      if (path.pathname.includes("orders")) {
        this.sidetab = 1;
      } else if (path.pathname.includes("funds")) {
        this.sidetab = 2;
      } else {
        this.sidetab = 0;
      }
    },
    setColorstyle(id, value, type) {
      if (type == "div") {
        document.getElementById(id).style.backgroundColor = `var(--${value})`;
      } else {
        var idx = document.getElementById(id).classList;
        if (!idx.value.includes("maingreen--text") && value == "maingreen--text") {
          idx.add("maingreen--text");
          idx.remove("mainred--text");
          idx.remove("subtext--text");
        }
        if (!idx.value.includes("mainred--text") && value == "mainred--text") {
          idx.add("mainred--text");
          idx.remove("maingreen--text");
          idx.remove("subtext--text");
        }
        if (!idx.value.includes("subtext--text") && value == "subtext--text") {
          idx.add("subtext--text");
          idx.remove("maingreen--text");
          idx.remove("mainred--text");
        }
      }
    },
    goSso() {
      window.location.assign(`https://desk.mynt.in/mynt/?url=${window.location.origin + window.location.pathname}`);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    setLoaderstatus(value) {
      this.subloader = value == 0 ? false : true;
    },
    snackAlert(color, msg) {
      const regex = /^Error Occurred : \d+ "no data"$/;
      if (JSON.stringify(msg) == "[]" || regex.test(msg)) {
        //
      } else if (msg) {
        console.log(msg, msg.length, typeof msg);
        this.snackbar = true;
        if (msg && (msg.message == "Request failed with status code 401" || (typeof msg == "string" && msg.includes("Session Expired")) || msg == "mobile_unique not valid")) {
          this.snackcolor = "warning";
          this.snacktxt = "Session is invalid or has expired. Please log in once more.";
          setTimeout(() => {
            this.$router.push("/");
            eventBus.$emit("storage-reset", true);
          }, 100);
        } else if (msg) {
          this.snackcolor = color == 0 ? "error" : color == 1 ? "success" : "warning";
          this.snacktxt = msg;
        }
      }
      if (this.snackbar) {
        this.snackalerts.unshift({time: new Date().toLocaleTimeString("en-US"), msg: this.snacktxt});
        var logid = this.uid + new Date().toLocaleDateString();
        sessionStorage.setItem(logid, JSON.stringify(this.snackalerts));
      }
    },
    async setMenudialog(type, token, exch, tsym, trantype, item) {
      console.log(type, token, exch, tsym, trantype, item);
      this.subloader = true;
      this.orderpoploader = false;
      this.menudata = [];
      this.upiidfield = false;
      if (type == "detail") {
        this.$router.push({name: "single stocks", params: {token: token, tsym: tsym, exch: exch}});
      } else if (type == "mforder") {
        this.mf_tenure = token == "sip" ? true : false;
        this.menudata["types"] = token;
        this.menudata["item"] = exch.red;
        if (token == "redem") {
          this.menudata.item.Minimum_Redemption_Qty = Number(this.menudata.item.Minimum_Redemption_Qty);
          this.menudata["netqty"] = exch.red.NET - exch.red.COLQTY - exch.red.PLEDGE_QTY;
          this.menudata["sname"] = exch.quotes.symname;

          this.mf_redqty = this.menudata.item.Minimum_Redemption_Qty;
          this.mf_invest_amt = (this.mf_redqty * exch.red.NET_ASSET_VALUE).toFixed(2);
          this.mforderdialog = true;
        } else {
          if (exch.SIP_FLAG == "Y") {
            let sipvalue = await getMFsipvalue([exch.ISIN, exch.Scheme_Code]);
            if (sipvalue.stat == "Ok") {
              this.menudata["sipvalue"] = {};
              this.menudata["mf_frequencys"] = [];
              for (let i = 0; i < sipvalue.data.length; i++) {
                let freq = sipvalue.data[i].SIP_FREQUENCY.slice(0, 1) + sipvalue.data[i].SIP_FREQUENCY.slice(1, sipvalue.data[i].SIP_FREQUENCY.length).toLocaleLowerCase();
                this.menudata.mf_frequencys.push(freq);
                this.menudata.sipvalue[freq] = sipvalue.data[i];
                this.menudata.sipvalue[freq]["mf_dates"] = sipvalue.data[i].SIP_DATES ? sipvalue.data[i].SIP_DATES.split(",") : ["0"];
                this.menudata.sipvalue[freq]["mf_date"] = this.menudata.sipvalue[freq]["mf_dates"][0];
                this.menudata.sipvalue[freq]["freqis"] = freq == "Daily" ? "Days" : freq == "Monthly" ? "Months" : freq == "Quarterly" ? "Qtrs" : freq;

                this.menudata.sipvalue[freq]["SIP_INSTALLMENT_GAP"] = this.menudata.sipvalue[freq]["SIP_INSTALLMENT_GAP"] ? Number(this.menudata.sipvalue[freq]["SIP_INSTALLMENT_GAP"]) : "";
                this.menudata.sipvalue[freq]["SIP_MINIMUM_GAP"] = this.menudata.sipvalue[freq]["SIP_MINIMUM_GAP"] ? Number(this.menudata.sipvalue[freq]["SIP_MINIMUM_GAP"]) : "";
                this.menudata.sipvalue[freq]["SIP_MAXIMUM_GAP"] = this.menudata.sipvalue[freq]["SIP_MAXIMUM_GAP"] ? Number(this.menudata.sipvalue[freq]["SIP_MAXIMUM_GAP"]) : "";
                this.menudata.sipvalue[freq]["SIP_MINIMUM_INSTALLMENT_AMOUNT"] = this.menudata.sipvalue[freq]["SIP_MINIMUM_INSTALLMENT_AMOUNT"] ? Number(this.menudata.sipvalue[freq]["SIP_MINIMUM_INSTALLMENT_AMOUNT"]) : "";
                this.menudata.sipvalue[freq]["SIP_MAXIMUM_INSTALLMENT_AMOUNT"] = this.menudata.sipvalue[freq]["SIP_MAXIMUM_INSTALLMENT_AMOUNT"] ? Number(this.menudata.sipvalue[freq]["SIP_MAXIMUM_INSTALLMENT_AMOUNT"]) : "";
                this.menudata.sipvalue[freq]["SIP_MINIMUM_INSTALLMENT_NUMBERS"] = this.menudata.sipvalue[freq]["SIP_MINIMUM_INSTALLMENT_NUMBERS"] ? Number(this.menudata.sipvalue[freq]["SIP_MINIMUM_INSTALLMENT_NUMBERS"]) : "";
                this.menudata.sipvalue[freq]["SIP_MAXIMUM_INSTALLMENT_NUMBERS"] = this.menudata.sipvalue[freq]["SIP_MAXIMUM_INSTALLMENT_NUMBERS"] ? Number(this.menudata.sipvalue[freq]["SIP_MAXIMUM_INSTALLMENT_NUMBERS"]) : "";
                this.menudata.sipvalue[freq]["SIP_MULTIPLIER_AMOUNT"] = this.menudata.sipvalue[freq]["SIP_MULTIPLIER_AMOUNT"] ? Number(this.menudata.sipvalue[freq]["SIP_MULTIPLIER_AMOUNT"]) : "";
              }
              this.getMFmandata();
              this.mf_frequency = this.menudata.mf_frequencys[0];
              this.mf_instal_amt = this.menudata.sipvalue[this.mf_frequency].SIP_MINIMUM_INSTALLMENT_AMOUNT;
              this.mf_noof_instal = this.menudata.sipvalue[this.mf_frequency].SIP_MINIMUM_INSTALLMENT_NUMBERS;
              this.setDefaultmandate();
            } else {
              this.menudata["sipvalue"] = false;
              // this.snackAlert(2, sipvalue.msg ? sipvalue.msg : sipvalue);
            }
          } else {
            this.menudata["sipvalue"] = false;
          }
          let bank = await getMFBankdetails();
          if (bank && bank.stat == "Ok" && bank.data) {
            this.menudata.mf_bankaccs = bank.data;
          }
          let upis = await getFundsupis(this.uid);
          if (upis.stat == "Ok" && upis.data[0] && upis.data[0].upi_id && upis.data[0].client_id == this.uid) {
            this.mfpainids = upis.data[0].upi_id;
          } else {
            this.mfpainids = null;
          }
          this.mf_accact = null;
          this.mfpayinmode = null;
          this.mf_sipinit = false;
          exch.Minimum_Purchase_Amount = Number(exch.Minimum_Purchase_Amount);
          exch.Purchase_Amount_Multiplier = Number(exch.Purchase_Amount_Multiplier);
          this.mf_invest_amt = exch.Minimum_Purchase_Amount;
          this.mf_initial_amt = exch.Minimum_Purchase_Amount;
          this.mforderdialog = true;
        }
        this.subloader = false;
      } else if (type == "bondorder") {
        this.menudata = token;
        this.menudata["ledger"] = await getBondLedger([this.uid, this.token]);
        this.menudata.ledger = this.menudata.ledger.total > 0 ? Number(this.menudata.ledger.total).toFixed(2) : null;
        this.menudata["type"] = exch;
        this.bondqty = token.minbidqty;
        this.bondorderdialog = true;
        this.subloader = false;
      } else if (type == "iposorder") {
        this.menudata = token;
        this.menudata["flow"] = exch;
        if (this.menudata.flow == "can") {
          setTimeout(() => {
            this.setIposorder();
          }, 100);
        } else {
          this.iposbids = [];
          let upis = await getFundsupis(this.uid);
          if (upis.stat == "Ok" && upis.data[0] && upis.data[0].upi_id && upis.data[0].client_id == this.uid) {
            this.iposupiid = upis.data[0].upi_id;
          } else {
            this.iposupiid = "";
          }

          if (exch == "mod") {
            this.menudata["name"] = token.company_name;
            this.menudata["maxPrice"] = token.maxprice;
            this.menudata["minPrice"] = token.minprice;
            this.menudata["cutOffPrice"] = token.cutoffprice;
            this.menudata["issueSize"] = token.issuesize ? token.issuesize : "-";
            this.menudata["minBidQuantity"] = token.minbidquantity ? token.minbidquantity : token.bid_detail[0].quantity;
            this.menudata["lotSize"] = token.minbidquanlotsizetity ? token.lotsize : token.bid_detail[0].quantity;
            this.iposbids = [
              {name: "Bid-01", bitis: token.bid_detail[0] ? true : false, qty: token.minbidquantity, price: token.bid_detail[0].price, cutoff: token.bid_detail[0].atCutOff},
              {name: "Bid-02", bitis: token.bid_detail[1] ? true : false, qty: token.minbidquantity, price: token.bid_detail[1] ? token.bid_detail[1].price : token.maxprice, cutoff: token.bid_detail[1] ? token.bid_detail[1].atCutOff : true},
              {name: "Bid-03", bitis: token.bid_detail[2] ? true : false, qty: token.minbidquantity, price: token.bid_detail[2] ? token.bid_detail[2].price : token.maxprice, cutoff: token.bid_detail[2] ? token.bid_detail[2].atCutOff : true},
            ];
          } else {
            this.iposbids = [
              {name: "Bid-01", bitis: true, qty: token.minBidQuantity, price: token.maxPrice, cutoff: true},
              {name: "Bid-02", bitis: false, qty: token.minBidQuantity, price: token.maxPrice, cutoff: true},
              {name: "Bid-03", bitis: false, qty: token.minBidQuantity, price: token.maxPrice, cutoff: true},
            ];
          }

          // if (exch == 0) {
          if (token.subCategorySettings) this.menudata["categorys"] = [];
          // if (token.type == "BSE") {
          //   this.menudata.categorys = [
          //     {
          //       key: "IND",
          //       val: "Individual",
          //       data: {
          //         allowCutOff: true,
          //         allowUpi: true,
          //         caCode: "RETAIL",
          //         discountPrice: null,
          //         discountType: "",
          //         maxQuantity: null,
          //         maxUpiLimit: 500000.0,
          //         maxValue: 500000.0,
          //         minValue: 0.0,
          //         subCatCode: "IND",
          //       },
          //     },
          //   ];
          //   this.menudata["category"] = this.menudata.categorys[0];
          // } else
          if (token.subCategorySettings) {
            for (var q = 0; q < token.subCategorySettings.length; q++) {
              // IND - Individual
              // EMP - Employee
              // SHA - Shareholder
              // POL - Policyholder
              if (token.subCategorySettings[q].allowUpi) {
                if (token.subCategorySettings[q].subCatCode == "IND") {
                  this.menudata.categorys.push({key: "IND", val: "Individual", data: token.subCategorySettings[q]});
                }
                if (token.subCategorySettings[q].subCatCode == "EMP") {
                  this.menudata.categorys.push({key: "EMP", val: "Employee", data: token.subCategorySettings[q]});
                }
                if (token.subCategorySettings[q].subCatCode == "SHA") {
                  this.menudata.categorys.push({key: "SHA", val: "Shareholder", data: token.subCategorySettings[q]});
                }
                if (token.subCategorySettings[q].subCatCode == "POL") {
                  this.menudata.categorys.push({key: "POL", val: "Policyholder", data: token.subCategorySettings[q]});
                }
                this.menudata["category"] = this.menudata.categorys[0];
              }
              // }
            }
          } else {
            this.menudata.categorys = [
              {
                key: "MODI",
                val: "modify",
                data: {
                  allowCutOff: true,
                  allowUpi: true,
                  caCode: "modify",
                  discountPrice: null,
                  discountType: "",
                  maxQuantity: null,
                  maxUpiLimit: 500000.0,
                  maxValue: 500000.0,
                  minValue: 0.0,
                  subCatCode: "MODI",
                },
              },
            ];
            this.menudata["category"] = this.menudata.categorys[0];
          }
          this.ipoCalcs();
          this.iposcheckbox = false;
          this.iposorderdialog = true;
          this.subloader = false;
        }
      } else if (type != "watch") {
        let quotesdata = await getQuotesdata(`${exch}|${token}`);
        this.menudata.push(quotesdata);
        this.menudata["settype"] = null;
        if (type == "alert" || type == "m-alert") {
          if (type == "m-alert") {
            this.condition = item.ai_t ? (item.ai_t.includes("_A") ? ">" : item.ai_t.includes("_B") ? "<" : ">") : ">";
            this.alertis = item.ai_t
              ? item.ai_t.includes("LTP_")
                ? "LTP"
                : item.ai_t.includes("CH_PER_")
                ? "Perc. change"
                : item.ai_t.includes("ATP_")
                ? "ATP"
                : item.ai_t.includes("OI_")
                ? "OI"
                : item.ai_t.includes("TOI_")
                ? "TOI"
                : item.ai_t.includes("_52HIGH")
                ? "52HIGH"
                : item.ai_t.includes("_52LOW")
                ? "52LOW"
                : item.ai_t.includes("VOLUME_")
                ? "VOLUME"
                : "LTP"
              : "LTP";
            this.alertvalue = item.d;
            this.menudata["malert"] = true;
            this.menudata["alertd"] = item;
          } else {
            this.menudata["malert"] = false;
            this.condition = ">";
            this.alertis = "LTP";
            this.alertvalue = null;
          }
          this.alertdialog = true;
          this.subloader = false;
        } else if (type == "cancel-order") {
          this.menudata["settype"] = "can";
          this.menudata["iitem"] = item;
          this.setPlaceorder();
        } else if (type == "order" || type == "order-GTT" || type == "mod-GTT" || type == "exit-order" || type == "re-order" || type == "mod-order") {
          var orepre = JSON.parse(localStorage.getItem(`${this.uid}_ordpref`));
          var ordtype = orepre.mainpreitems[exch];
          ordtype[3] = Number(ordtype[3]) > 0 ? Number(ordtype[3]) : 1;
          if (quotesdata && quotesdata.instname != "UNDIND" && quotesdata.instname != "COM") {
            let secudata = await getSecuritydata(`${exch}|${token}`);
            this.menudata.push(secudata);
            if (type == "re-order" || type == "mod-order") {
              this.prc = item.prctyp; //MKT
              this.orddisqty = item.dscqty > 0 ? Number(item.dscqty) : 0;
              this.addvalqty = item.dscqty > 0 && ["IOC", "EOS", "DAY"].includes(item.ret) ? true : false;
              this.ordqty = Number(Math.abs(item.qty));
              this.ordprice = item.prctyp != "MKT" && item.prctyp != "SL-MKT" ? Number(item.prc) : 0;
              this.ordvalqtyprice = item.ret ? item.ret : exch == "BFO" || exch == "BCD" ? "EOS" : "DAY";
              this.addmktord = item.amo == "Yes" ? true : false;
              this.buyrsell = trantype == "s" ? true : false;
              this.ordertype = item.prd == "H" ? 1 : item.prd == "B" ? 2 : 0;
              this.investype = item.prd != "H" && item.prd != "B" ? item.prd : ordtype[0] == "NRML" ? "M" : ordtype[0] == "CNC" ? "C" : ordtype[0] == "MIS" ? "I" : "I";
              this.ordslprice = item.prd == "H" || item.prd == "B" ? Number(item.blprc) : 0;
              this.ordtagprice = item.prd == "B" ? Number(item.bpprc) : 0;
              this.ordtrgprice = item.prctyp != "MKT" && item.prctyp != "LMT" ? item.trgprc : 0;
              this.mktprdordpec = item.mkt_protection ? Number(item.mkt_protection) : 5;
              // // "trailprc": '2',
              if (type == "mod-order") {
                this.menudata["settype"] = "mod";
              }
              this.menudata["iitem"] = item;
            } else if (type == "mod-GTT") {
              this.ordertype = 3;
              this.gttalert = item.res.ai_t.includes("LTP_")
                ? "LTP"
                : item.res.ai_t.includes("CH_PER_")
                ? "Perc. change"
                : item.res.ai_t.includes("ATP_")
                ? "ATP"
                : item.res.ai_t.includes("OI_")
                ? "OI"
                : item.res.ai_t.includes("TOI_")
                ? "TOI"
                : item.res.ai_t.includes("_52HIGH")
                ? "52HIGH"
                : item.res.ai_t.includes("_52LOW")
                ? "52LOW"
                : item.res.ai_t.includes("VOLUME_")
                ? "VOLUME"
                : "";
              this.gttcond = item.res.ai_t.includes("A_O") ? ">" : "<";
              this.buyrsell = item.trantype == "S" ? true : false;
              if (item.res.place_order_params) {
                this.gttvalue = Number(item.res.oivariable[0].d);
                this.prc = item.res.place_order_params.prctyp;
                this.investype = item.res.place_order_params.prd;
                this.gttqty = item.res.place_order_params.qty;
                this.gttprice = Number(item.res.place_order_params.prc);
                this.gtttrgprice = Number(item.res.place_order_params.trgprc);
              }
              if (item.res.place_order_params_leg2) {
                this.gttocopanel = true;
                this.ocovalue = Number(item.res.oivariable[1].d);
                this.ocoprc = item.res.place_order_params_leg2.prctyp;
                this.ocoqty = item.res.place_order_params_leg2.qty;
                this.ocoprice = Number(item.res.place_order_params_leg2.prc);
                this.ocotrgprice = Number(item.res.place_order_params_leg2.trgprc);
              }
              this.gttBSM = "Modify";
              this.menudata["iitem"] = item;
            } else {
              this.prc = ordtype[1];
              this.ocoprc = ordtype[1];
              this.buyrsell = trantype == "s" ? true : false;
              if (type == "order-GTT") {
                this.gttcond = ">";
                this.gttocopanel = false;
                this.ordertype = 3;
                this.gttvalue = 0;
                this.ocoprice = 0;
                this.gttalert = "LTP";
                this.ocoalert = "LTP";
                this.gttqty = 1;
                this.ocoqty = 1;
              } else {
                this.ordertype = ordtype[0] == "CO" ? 1 : ordtype[0] == "BO" ? 2 : 0;
              }
              if (type == "exit-order") {
                this.menudata["exitqrty"] = false;
                this.ordqty = Math.abs(item.netqty);
                this.prc = "MKT";
                this.investype = item.s_prdt_ali == "NRML" ? "M" : item.s_prdt_ali == "CNC" ? "C" : item.s_prdt_ali == "MIS" ? "I" : "I";
              } else {
                this.investype = ordtype[0] == "NRML" ? "M" : ordtype[0] == "CNC" ? "C" : ordtype[0] == "MIS" ? "I" : "I";
                this.menudata["exitqrty"] = true;
                this.menudata["flow"] = true;
                this.ordqty = orepre.qtypre == "1" ? Number(quotesdata.ls) * ordtype[3] : Number(quotesdata.ls);
                this.mktprdordpec = Number(orepre.mktpro) > 0 ? Number(orepre.mktpro) : 5;
              }
            }
            this.orderdialog = true;
            this.setDragableSett();
            this.setOrdertypes();
            this.getMargin();
          } else {
            this.snackAlert(2, `Non-tradable symbol, You can't trade the symbol ${quotesdata.tsym}.`);
            this.subloader = false;
            this.menudata = [];
          }
        }
        let wsdata = [quotesdata];
        this.setWebsocket("sub", wsdata, "menu");
        this.menudata[0].ls = Number(quotesdata.ls);
        this.menudata[0].ti = Number(quotesdata.ti);
        this.menudata[0].lp = Number(quotesdata.lp);
        this.menudata[0]["wk52_l"] = this.menudata[0]["wk52_l"] && Number(this.menudata[0]["wk52_l"]) > 0 ? Number(this.menudata[0]["wk52_l"]).toFixed(2).toLocaleString() : 0;
        this.menudata[0]["wk52_h"] = this.menudata[0]["wk52_h"] && Number(this.menudata[0]["wk52_h"]) > 0 ? Number(this.menudata[0]["wk52_h"]).toFixed(2).toLocaleString() : 0;
        this.subloader = false;
      } else {
        this.subloader = false;
        // this.$router.push({ name: 'watchlist', params: { tsym: `${exch}|${token}` } });
      }
    },
    async setAlert() {
      this.alertloader = true;
      let alertis =
        this.alertis == "LTP"
          ? this.condition == ">"
            ? "LTP_A"
            : "LTP_B"
          : this.alertis == "Perc. change"
          ? this.condition == ">"
            ? "CH_PER_A"
            : "CH_PER_B"
          : this.alertis == "ATP"
          ? this.condition == ">"
            ? "ATP_A"
            : "ATP_B"
          : this.alertis == "OI"
          ? this.condition == ">"
            ? "OI_A"
            : "OI_B"
          : this.alertis == "TOI"
          ? this.condition == ">"
            ? "TOI_A"
            : "TOI_B"
          : this.alertis == "52HIGH"
          ? this.condition == ">"
            ? "LTP_A_52HIGH"
            : "LTP_B_52LOW"
          : this.alertis == "VOLUME"
          ? this.condition == ">"
            ? "VOLUME_A"
            : ""
          : "";
      let data = {uid: `${this.uid}`, exch: `${this.menudata[0].exch}`, tsym: `${this.menudata[0].tsym}`, ai_t: `${alertis}`, validity: "GTT", d: `${this.alertvalue}`, remarks: `${this.alertremarks}`};
      if (this.menudata.malert) {
        data["al_id"] = this.menudata.alertd.al_id;
      }
      let alert = await setMalert(data, this.menudata.malert);
      if (alert.stat == "OI created" || alert.stat == "OI replaced") {
        this.snackAlert(1, `Alert has been ${this.menudata.malert ? "modify" : "set"} for ${this.menudata[0].tsym}`);
        eventBus.$emit("orderbook-update", "orders");
      } else {
        this.snackAlert(2, alert.emsg ? alert.emsg : alert);
      }
      this.closeMenudialog("alert");
      this.alertloader = false;
      this.alertis = "LTP";
      this.condition = ">";
      this.alertvalue = 0;
      this.alertremarks = "";
    },
    async setGttplaceorder() {
      this.orderloader = true;
      let item;

      let con = this.gttcond == ">" ? "A_O" : "B_O";
      let aler = this.gttalert == "Perc. change" ? "CH_PER" : this.gttalert;
      let ait = `${aler}_${con}`;

      if (this.gttocopanel) {
        item = {
          uid: this.uid,
          ai_t: "LMT_BOS_O",
          al_id: this.gttBSM == "Modify" ? this.menudata.iitem.al_id : "",
          validity: "GTT",
          tsym: this.menudata[0].tsym,
          exch: this.menudata[0].exch,
          oivariable: [
            {d: this.gttvalue.toString(), var_name: "x"},
            {d: this.ocovalue.toString(), var_name: "y"},
          ],
          place_order_params: {
            tsym: this.menudata[0].tsym,
            exch: this.menudata[0].exch,
            trantype: !this.buyrsell ? "B" : "S",
            prctyp: this.prc.toString(),
            prd: this.investype,
            ret: "DAY",
            actid: this.uid,
            uid: this.uid,
            ordersource: "TP",
            qty: (this.menudata[0].exch == "MCX" ? Number(this.menudata[0].ls) * this.gttqty : this.gttqty).toString(),
            prc: this.gttprice.toString(),
            trgprc: this.gtttrgprice.toString(),
          },
          place_order_params_leg2: {
            tsym: this.menudata[0].tsym,
            exch: this.menudata[0].exch,
            trantype: !this.buyrsell ? "B" : "S",
            prctyp: this.ocoprc.toString(),
            prd: this.investype,
            ret: "DAY",
            actid: this.uid,
            uid: this.uid,
            ordersource: "TP",
            qty: (this.menudata[0].exch == "MCX" ? Number(this.menudata[0].ls) * this.gttqty : this.ocoqty).toString(),
            prc: this.ocoprice.toString(),
            trgprc: this.ocotrgprice.toString(),
          },
        };
      } else {
        item = {
          uid: this.uid,
          tsym: this.menudata[0].tsym,
          exch: this.menudata[0].exch,
          ai_t: ait,
          al_id: this.gttBSM == "Modify" ? this.menudata.iitem.al_id : "",
          validity: "GTT",
          d: this.gttvalue.toString(),
          trantype: !this.buyrsell ? "B" : "S",
          prctyp: this.prc,
          prd: this.investype,
          ret: "DAY",
          actid: this.uid,
          qty: (this.menudata[0].exch == "MCX" ? Number(this.menudata[0].ls) * this.gttqty : this.gttqty).toString(),
          prc: this.gttprice.toString(),
          trgprc: this.gtttrgprice.toString(),
        };
      }

      var url = `${this.gttBSM == "Modify" ? "ModifyGTTOrder" : this.gttocopanel ? "PlaceOCOOrder" : "PlaceGTTOrder"}`;

      let data = await getGTTPlaceOrder(item, url);
      let penalerts = JSON.parse(JSON.stringify(data));
      if (penalerts.stat == "OI created" || penalerts.stat == "OI replaced") {
        this.snackAlert(1, penalerts.stat == "OI created" ? `GTT Order have been Placed` : `GTT Order have been Modified`);
        eventBus.$emit("orderbook-update", "gtt");
      } else {
        this.snackAlert(2, penalerts.emsg ? penalerts.emsg : penalerts);
      }
      this.closeMenudialog("order");
      this.orderloader = false;
    },
    setUPPlaceorder(loop) {
      this.menudata["sliceorder"] = false;
      if (loop) {
        for (let i = 0; i < this.menudata.fzqty.length; i++) {
          if (this.menudata.fzqty[i] > 0) {
            this.setPlaceorder(i == this.menudata.fzqty.length - 1 ? null : true, this.menudata.fzqty[i]);
          }
        }
      } else {
        if (this.ordqty <= Number(this.menudata[1].frzqty) * 28) {
          let fq = Number(this.menudata[1].frzqty);
          if (this.ordqty > fq) {
            this.menudata["fzqty"] = [];
            this.menudata["fq"] = Math.trunc(Number(this.ordqty) / fq);
            for (let i = 0; i < this.menudata.fq; i++) {
              this.menudata.fzqty.push(fq);
            }
            this.menudata.fzqty.push(Number(this.ordqty) - fq * this.menudata.fq);
            // this.setUPPlaceorder(true)
            // this.snackAlert(2, `Order Quantity can not be greater than Freeze Quantity`);
            this.menudata.sliceorder = true;
            this.orderdialog = false;
          } else {
            this.menudata.sliceorder = false;
            this.orderdialog = true;
            this.setDragableSett();
            this.setPlaceorder();
            this.menudata["fq"] = null;
          }
        } else {
          this.snackAlert(2, "Quantity cannot be split more than 28 times.");
        }
      }
    },
    async setPlaceorder(loop, fqty) {
      let bskis = false;
      if (this.menudata.settype != "can") {
        if (!(this.ordqty > 0)) {
          this.snackAlert(2, "The order Quantity must be greater than zero.");
        } else if (((this.prc == "LMT" || this.prc == "SL-LMT") && !(Number(this.ordprice) > Number(this.menudata[0].lc))) || !(Number(this.ordprice) < Number(this.menudata[0].uc))) {
          this.snackAlert(2, "The order price must be between the circuit level.");
        } else if (this.prc == "SL-MKT" || (this.prc == "SL-LMT" && !(Number(this.ordtrgprice) > 0))) {
          this.snackAlert(2, "The trigger price cannot be zero.");
        } else if ((this.ordertype == 1 || this.ordertype == 2) && !(Number(this.ordslprice) > 0)) {
          this.snackAlert(2, "The Stoploss price cannot be zero.");
        } else if (this.ordertype == 2 && !(Number(this.ordtagprice) > 0)) {
          this.snackAlert(2, "The Target price cannot be zero.");
        } else if (this.addvalqty && !(this.orddisqty > 0)) {
          this.snackAlert(2, "Disclosed Qty must be greater than zero.");
        } else if (!(this.ordqty <= Number(this.menudata[1].frzqty) * 28)) {
          this.snackAlert(2, "You have reached max freeze Quantity");
        } else {
          bskis = true;
        }
      } else {
        bskis = true;
      }
      if (bskis) {
        this.orderloader = true;
        let item = {};
        if (this.menudata.settype == "can") {
          item = {
            uid: this.uid,
            norenordno: this.menudata.iitem.norenordno,
          };
        } else {
          item = {
            uid: this.uid,
            actid: this.uid,
            // "amo": this.addmktord ? 'Yes' : '',
            // "blprc": this.ordertype == 1 || this.ordertype == 2 ? this.ordslprice.toString() : '',
            // "bpprc": this.ordertype == 2 ? this.ordtagprice.toString() : '',
            // "dscqty": this.addvalqty ? this.orddisqty.toString() : '',
            exch: this.menudata[0].exch,
            tsym: this.menudata[0].tsym,
            ret: this.addvalqty ? this.ordvalqtyprice : "DAY",
            qty: fqty > 0 ? fqty.toString() : this.ordqty.toString(),
            prc: (this.menudata[0].exch == "MCX" || this.menudata[0].exch == "BSE") && (this.prc == "MKT" || this.prc == "SL-MKT") ? "" : this.ordprice.toString(),
            prd: this.ordertype == 1 ? "H" : this.ordertype == 2 ? "B" : this.investype,
            trantype: this.buyrsell ? "S" : "B",
            prctyp: this.prc,
            // "trgprc": (this.prc == "SL-LMT" || this.prc == "SL-MKT") ? this.ordtrgprice.toString() : '',
            // "mktProt": (this.prc == 'MKT' || this.prc == 'SL-MKT') ? this.mktprdordpec.toString() : '',
            // "trailprc": '2',
            channel: "",
            userAgent: "",
            appInstaId: "",
          };
          this.addmktord ? (item["amo"] = "Yes") : "";
          this.ordertype == 1 || this.ordertype == 2 ? (item["blprc"] = this.ordslprice.toString()) : "";
          this.ordertype == 2 ? (item["bpprc"] = this.ordtagprice.toString()) : "";
          this.addvalqty ? (item["dscqty"] = this.orddisqty.toString()) : "";
          this.prc == "SL-LMT" || this.prc == "SL-MKT" ? (item["trgprc"] = this.ordtrgprice.toString()) : "";
          this.prc == "MKT" || this.prc == "SL-MKT" ? (item["mktProt"] = this.mktprdordpec.toString()) : "";
          this.menudata.settype == "mod" ? (item["norenordno"] = this.menudata.iitem.norenordno) : "";
        }
        let data = await getPlaceOrder(item, this.menudata.settype);
        if (data.stat == "Ok") {
          this.wsorderalertdata.push({
            message: "Your " + this.menudata.settype == "can" ? "Order canceled successfully." : this.menudata.settype == "mod" ? `Order modified and placed successfully.` : `Order placed successfully.`,
            timeout: 4000,
            color: "success",
          });
          if (loop != true) {
            this.menudata = [];
            this.closeMenudialog("order");
          }
          // this.snackAlert(1, this.menudata.settype == "can" ? "Order canceled successfully." : this.menudata.settype == "mod" ? `Order modified and placed successfully.` : `Order placed successfully.`);
        } else {
          this.snackAlert(2, data && data.emsg ? data.emsg : data);
        }
        eventBus.$emit("orderbook-update", "orders");

        if (loop != true) {
          this.orderloader = false;
        }
      }
    },
    async getMargin() {
      let item = {
        exch: this.menudata[0].exch,
        tsym: this.menudata[0].tsym,
        qty: this.ordqty,
        prc: this.ordprice,
        prd: this.investype,
        trantype: this.buyrsell ? "S" : "B",
        prctyp: this.prc,
        trgprc: this.prc == "SL-LMT" || this.prc == "SL-MKT" ? this.ordtrgprice : 0,
      };
      this.getBroker();
      let data = await getOrderMargin(item);
      if (data.stat == "Ok") {
        this.menudata["margin"] = Number(data.marginused);
        this.menudata["mremarks"] = data.remarks;
      }
      this.menudata = {...this.menudata};
    },
    async getBroker() {
      let item = {
        exch: this.menudata[0].exch,
        tsym: this.menudata[0].tsym,
        qty: this.ordqty,
        prc: this.ordprice,
        prd: this.investype,
        trantype: this.buyrsell ? "S" : "B",
        prctyp: this.prc,
        trgprc: this.prc == "SL-LMT" || this.prc == "SL-MKT" ? this.ordtrgprice : 0,
      };
      let data = await getBrokerage(item);
      if (data.stat == "Ok") {
        this.menudata["charges"] = Number(data.brkage_amt);
        this.menudata["allcharges"] = data;
      }
    },
    setOrdertypes() {
      if (this.ordertype != 0 && this.ordertype != 3 && this.prc == "SL-MKT") {
        this.prc = "LMT";
      }
      if (this.ordertype != 3) {
        this.menudata.exitqrty && !this.menudata.flow ? (this.ordqty = Number(this.menudata[0].ls)) : null;
      } else {
        this.gttqty = Number(this.menudata[0].ls);
        this.ocoqty = Number(this.menudata[0].ls);
      }
      if (this.ordertype == 0) {
        if (this.prc == "MKT" || this.prc == "SL-MKT") {
          this.ordprice = 0;
        } else if (this.prc == "LMT" || this.prc == "SL-LMT") {
          this.ordprice = this.menudata[0].lp;
        }
      } else if (this.ordertype == 1) {
        if (this.prc == "MKT") {
          this.ordprice = 0;
        } else if (this.prc == "LMT" || this.prc == "SL-LMT") {
          this.ordprice = this.menudata[0].lp;
        }
      } else if (this.ordertype == 2) {
        if (this.prc == "MKT") {
          this.ordprice = 0;
        } else if (this.prc == "LMT" || this.prc == "SL-LMT") {
          this.ordprice = this.menudata[0].lp;
        }
      } else if (this.ordertype == 3) {
        if (this.gttvalue > 0 && this.gttqty > 0 && (this.gttocopanel ? this.ocovalue > 0 && this.ocoqty > 0 : true)) {
          this.gttplacebtn = false;
        } else {
          this.gttplacebtn = true;
        }

        if (this.prc == "MKT" || this.prc == "SL-MKT") {
          this.gttprice = 0;
        } else if (this.prc == "LMT" || this.prc == "SL-LMT") {
          this.gttprice = this.menudata[0].lp;
        }
        if (this.ocoprc == "MKT" || this.ocoprc == "SL-MKT") {
          this.ocoprice = 0;
        } else if (this.ocoprc == "LMT" || this.ocoprc == "SL-LMT") {
          this.ocoprice = this.menudata[0].lp;
        }
      }
      if (this.menudata.settype != "mod") {
        this.gtttrgprice = 0;
        this.ocotrgprice = 0;
        this.ordtrgprice = 0;
        this.ordtagprice = 0;
        this.ordslprice = 0;
      }
    },
    setOrderTabs() {
      if (this.ordertype == 4) {
        let path = window.location;
        if (path.pathname != "/orders") {
          this.$router.push({name: "orderbook", params: this.menudata[1]});
        } else {
          eventBus.$emit("siporder-trigger", this.menudata[1]);
        }
        this.closeMenudialog("order");
      } else {
        if (this.ordertype != 3) {
          // this.getMargin();
        } else {
          this.gttocopanel = false;
          this.prc = "LMT";
          this.gttcond = ">";
          this.gttalert = "LTP";
        }
        this.setOrdertypes();
      }
    },
    setDefaultmandate() {
      this.creatManmffrommodel = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1).toISOString().slice(0, 10);
      this.creatManmftomodel = new Date(new Date().getFullYear() + 30, new Date(this.creatManmffrommodel).getMonth(), new Date().getDate()).toISOString().slice(0, 10);
    },
    setMForder() {
      this.orderpoploader = true;
      let data;
      if (this.mf_tenure && this.menudata.types != "redem") {
        data = {
          trans_code: "NEW", //NEW/CXL
          scheme_code: this.mf_initial_amt >= 200000 ? `${this.menudata.item.Scheme_Code}-L1` : this.menudata.item.Scheme_Code, //EARG
          client_code: this.uid, //10099
          trans_mode: "D", //D/P
          // "dp_trans_mode": "P", //D/P
          start_date: this.menudata.sipvalue[this.mf_frequency].mf_date.toString(),
          freq_type: this.mf_frequency.toUpperCase(),
          freq_allowed: "1", //1
          installment_amt: this.mf_instal_amt.toString(),
          no_of_installment: this.mf_noof_instal.toString(),
          // "euin_number": `E${Math.floor(100000 + Math.random() * 900000)}`,
          euin_flag: "N",
          first_order: this.mf_sipinit ? "Y" : "N",
          end_date: this.menudata.sipvalue[this.mf_frequency].mf_date,
          // "dpc": "Y"
          mandate_id: this.mf_mandate.MandateId,
        };
      } else if (this.menudata.types == "redem") {
        data = {
          client_code: this.uid,
          scheme_code: this.menudata.item.Scheme_Code,
          buy_sell_type: "FRESH",
          amount: "",
          // amount: this.mf_invest_amt.toString(),
          qty: this.mf_redqty.toString(),
          all_redeem: this.mf_redqty == this.menudata.netqty ? "Y" : "N",
          // folio_number: "31069267/81",
        };
      } else {
        data = {
          trans_code: "NEW", //NEW/MOD/CXL
          client_code: this.uid,
          scheme_code: this.mf_invest_amt >= 200000 ? `${this.menudata.item.Scheme_Code}-L1` : this.menudata.item.Scheme_Code, //'EARG'
          buy_sell: this.menudata.types == "redem" ? "R" : "P", //P/R
          buy_sell_type: "FRESH", //FRESH/ADDITIONAL
          dptxn: this.menudata.types == "all" ? "P" : "C", //C/N/P
          amount: this.mf_invest_amt.toString(),
          all_redeem: this.mf_redqty == this.menudata.netqty ? "Y" : "N",
          kyc_status: "Y",
          qty: this.mf_redqty.toString(),
          // "euin_number": `E${Math.floor(100000 + Math.random() * 900000)}`,
          euin_flag: "Y",
          min_redeem: "N",
          dpc: "Y",
        };
      }
      let url = this.menudata.types == "redem" ? "lumsum_redemption" : this.mf_tenure ? "/xsip_purchase" : "/lumsum_purchase";
      let config = {
        method: "post",
        url: apiurl.mfapi + url,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          let res = response.data;
          if (res.stat == "Ok" && res.response_message && res.order_number) {
            if (axiosThis.menudata.types == "redem") {
              axiosThis.closeMenudialog("mforder");
              axiosThis.orderpoploader = false;
              axiosThis.snackAlert(2, res.response_message ? res.response_message : res.error ? res.error : res);
            } else {
              if (axiosThis.mf_tenure) {
                if (axiosThis.mf_sipinit) {
                  axiosThis.setMfpayment(axiosThis.mf_initial_amt, res.OrderNumber);
                } else {
                  axiosThis.orderpoploader = false;
                  axiosThis.snackAlert(res.stat == "Ok" ? 1 : 0, res.response_message ? res.response_message : res.error ? res.error : res);
                }
                axiosThis.closeMenudialog("mforder");
              } else {
                axiosThis.setMfpayment(axiosThis.mf_invest_amt, res.order_number);
              }
            }
          } else {
            axiosThis.closeMenudialog("mforder");
            axiosThis.orderpoploader = false;
            axiosThis.snackAlert(res.stat == "Ok" ? 1 : 0, res.response_message ? res.response_message : res.error ? res.error : res);
          }
        })
        .catch((error) => {
          console.log(error);
          axiosThis.orderpoploader = false;
          axiosThis.snackAlert(0, error);
          axiosThis.closeMenudialog("mforder");
        });
    },
    setBondorder(mode, item) {
      mode == 0 ? (this.subloader = true) : (this.orderpoploader = true);
      let data =
        mode == 0
          ? {
              requestfor: "REMOVE",
              symbol: item.symbol,
              investmentValue: item.investmentValue,
              price: item.bid_detail.price,
              orderNumber: item.orderNumber,
              clientApplicationNumber: item.clientApplicationNumber,
            }
          : {
              requestfor: "BUY",
              symbol: this.menudata.symbol,
              investmentValue: this.menudata.flow == 3 ? this.menudata.minPrice * this.bondqty : this.bondqty * 100,
              price: this.menudata.cutoffPrice,
            };
      this.menudata.flow == 3 ? (data["bidQuantity"] = this.bondqty) : "";

      let url = this.menudata.flow == 3 ? "addSGBtoPortfolio" : "addNCBtoPortfolio";

      let config = {
        method: "post",
        url: apiurl.bondapi + url,
        headers: {
          "Content-Type": "application/json",
          clientid: this.uid,
          Authorization: this.token,
        },
        data: JSON.stringify(data),
      };
      let axiosThis = this;

      axios
        .request(config)
        .then((response) => {
          axiosThis.orderpoploader = false;
          axiosThis.subloader = false;
          if (response.data.status) {
            axiosThis.snackAlert(response.data.status == "success" ? 1 : 0, response.data.orderStatus_response ? `${mode == 0 ? item.symbol : axiosThis.menudata.name}` + ", " + response.data.orderStatus_response : response.data.reason ? response.data.reason : response.data);
            if (mode == 0) {
              eventBus.$emit("watch-load");
            }
          } else {
            axiosThis.snackAlert(2, response.data && response.data.emsg ? response.data.emsg : response.data.reason ? response.data.reason : response.data);
          }

          axiosThis.bondorderdialog = false;
          axiosThis.menudata = [];
        })
        .catch((error) => {
          axiosThis.snackAlert(0, error);
          axiosThis.bondorderdialog = false;
          axiosThis.orderpoploader = false;
          axiosThis.subloader = false;
        });
    },
    async setUpiValid() {
      if (this.$refs.iposupiform.validate()) {
        this.orderpoploader = true;
        let data = await getUpivpa([this.iposupiid, this.uid, this.token]);
        if (this.iposupiid == data.data.client_VPA && data.data.verified_VPA_status1 == "Available") {
          this.setIposorder();
        } else {
          this.upiidfield = true;
          this.upiIDerrors;
          // this.snackAlert(2, 'Invalid UPI ID')
          this.orderpoploader = false;
        }
      }
      if (!this.iposcheckbox) {
        var element = document.getElementById("check");
        element.scrollIntoView();
      }
    },
    setIposorder() {
      let bids = [];
      if (this.menudata.flow != "can") {
        for (let i = 0; i < this.iposbids.length; i++) {
          if (this.iposbids[i].bitis) {
            if (this.menudata.type == "NSE") {
              bids.push({
                activityType: this.menudata.flow == "mod" ? "modify" : this.menudata.flow == "can" ? "cancel" : "new", // cancel, modify
                quantity: this.iposbids[i].qty,
                atCutOff: this.iposbids[i].cutoff,
                price: this.iposbids[i].price.toString(),
                amount: this.iposbids[i].total.toString(),
                bidReferenceNumber: this.menudata.resp_bid && this.menudata.resp_bid[i] ? this.menudata.resp_bid[i].bidReferenceNumber.toString() : "",
              });
            } else {
              bids.push({
                actioncode: this.menudata.flow == "mod" ? "m" : this.menudata.flow == "can" ? "d" : "n", // d, m
                quantity: this.iposbids[i].qty.toString(),
                cuttoffflag: this.iposbids[i].cutoff,
                rate: this.iposbids[i].price.toString(),
                bidid: this.menudata.resp_bid && this.menudata.resp_bid[i] ? this.menudata.resp_bid[i].bidReferenceNumber.toString() : "",
                orderno: `1234${5 + i}`,
              });
            }
          }
        }
      }
      let data = {
        symbol: this.menudata.symbol,
        UPI: this.iposupiid,
        type: this.menudata.type,
        category: this.menudata.category.key,
        company_name: this.menudata.name,
        BID: bids,
      };
      if (this.menudata.flow == "can" || this.menudata.flow == "mod") {
        data["applicationNo"] = this.menudata.applicationNumber;
      }
      if (this.menudata.flow == "can") {
        data["BID"] = [this.menudata.type == "NSE" ? {activityType: "cancel"} : {actioncode: "d"}];
        delete data["UPI"];
        delete data["company_name"];
      }

      let config = {
        method: "post",
        url: apiurl.iposapi + "addIPOtoPortfolio" /*addIPOtoPortfolionew*/,
        headers: {
          "Content-Type": "application/json",
          clientid: this.uid,
          Authorization: this.token,
        },
        data: JSON.stringify(data),
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (response.data && response.data.msg == "order initiated success") {
            axiosThis.snackAlert(1, response.data.msg ? response.data.msg : response.data);
          } else {
            axiosThis.snackAlert(2, response.data.msg ? response.data.msg : response.data);
          }
          eventBus.$emit("orderbook-update", "ipo");

          axiosThis.menudata = [];
          axiosThis.iposbids = [];
          axiosThis.orderpoploader = false;
          axiosThis.subloader = false;
        })
        .catch((error) => {
          axiosThis.orderpoploader = false;
          axiosThis.subloader = false;
          axiosThis.snackAlert(0, error);
          console.log(error);
        });
    },
    ipoQtymultiper(i) {
      if (this.iposbids[i].qty > this.menudata.minBidQuantity) {
        setTimeout(() => {
          this.iposbids[i].qty = Math.round(this.iposbids[i].qty / this.menudata.minBidQuantity) * this.menudata.minBidQuantity;
        }, 2000);
      } else {
        // this.iposbids[i].qty = this.menudata.minBidQuantity
      }
      this.ipoCalcs();
    },
    ipoCalcs(key) {
      // var day = new Date();
      var i = null;
      var r = null;
      var m = null;
      if (this.iposbids[0].bitis) {
        if (key == "cate") {
          this.iposbids[0].qty = this.menudata.lotSize;
        }
        this.iposbids[0]["total"] = this.iposbids[0].qty * this.iposbids[0].price;
        i = 0;
        r = this.iposbids[0].price >= this.menudata.minPrice && this.iposbids[0].price <= this.menudata.maxPrice ? false : true;
        m = this.iposbids[0].qty < this.menudata.minBidQuantity ? true : false;
      }
      if (this.iposbids[1].bitis) {
        if (key == "cate") {
          this.iposbids[1].qty = this.menudata.lotSize;
        }
        this.iposbids[1]["total"] = this.iposbids[1].qty * this.iposbids[1].price;
        i = 1;
        r = r ? r : this.iposbids[1].price >= this.menudata.minPrice && this.iposbids[1].price <= this.menudata.maxPrice ? false : true;
        m = m ? m : this.iposbids[1].qty < this.menudata.minBidQuantity ? true : false;
      }
      if (this.iposbids[2].bitis) {
        if (key == "cate") {
          this.iposbids[2].qty = this.menudata.lotSize;
        }
        this.iposbids[2]["total"] = this.iposbids[2].qty * this.iposbids[2].price;
        i = 2;
        r = r ? r : this.iposbids[2].price >= this.menudata.minPrice && this.iposbids[2].price <= this.menudata.maxPrice ? false : true;
        m = m ? m : this.iposbids[2].qty < this.menudata.minBidQuantity ? true : false;
      }
      this.menudata["ipostotal"] = this.iposbids[i]["total"];
      this.menudata["iposmax"] = this.iposbids[i]["total"] >= (this.menudata.category && this.menudata.category.data && this.menudata.category.data.maxUpiLimit && this.menudata.category.data.maxUpiLimit > 0 ? this.menudata.category.data.maxUpiLimit : this.menudata.flow == 1 ? 200000 : 500000);
      this.menudata["iposrange"] = r;
      this.menudata["iposminqty"] = m;

      // var daydiff = (new Date(this.menudata.biddingEndDate).getTime() - day.getTime()) / (1000 * 60 * 60 * 24) + 1;
      // this.menudata["ipostime"] = daydiff <= 0 && day.getHours() < Number(this.menudata.dailyEndTime.slice(0, 2));
      // this.menudata['ipostime'] = (hrs >= Number(this.menudata.dailyStartTime.slice(0, 2)) && hrs < Number(this.menudata.dailyEndTime.slice(0, 2)))
    },
    handleScroll() {
      // e
      // var scrollPos = e.target.scrollTop;
      // var check = document.getElementById('check').offsetTop;
    },
    async getMFmandata() {
      let mandate = await getMFmandate([this.uid, ""]);
      if (mandate.stat == "Ok") {
        this.mf_mandates = mandate.data.MandateDetails;
        this.mf_mandate = mandate.data.MandateDetails[0];
      } else {
        this.mf_mandates = [];
        this.mf_mandate = null;
      }
      this.orderpoploader = false;
    },
    async setCreatMandate() {
      this.orderpoploader = true;
      let addmandate = await getMFAddmandate([this.uid, this.creatManmfamt, this.creatManmffrom, this.creatManmfto, this.creatManmRemarks]);
      if (addmandate.stat == "Ok") {
        this.snackAlert(1, addmandate.mandate ? `The mandate created was successful, ${addmandate.mandate}` : addmandate);
        this.mf_tenure = true;
        this.getMFmandata();
      } else {
        this.snackAlert(0, addmandate.error ? addmandate.error : addmandate);
        this.orderpoploader = false;
      }
    },
    async setmfUpiValid() {
      if (this.mfpayinmode == "UPI") {
        if (this.$refs.mfupiform.validate()) {
          this.orderpoploader = true;
          let data = await getUpivpa([this.mfpainids, this.uid, this.token]);
          if (this.mfpainids == data.data.client_VPA && data.data.verified_VPA_status1 == "Available") {
            this.setMForder();
          } else {
            this.upiidfield = true;
            this.upiIDerrors;
            // this.snackAlert(2, 'Invalid UPI ID')
            this.orderpoploader = false;
          }
        }
      } else {
        this.setMForder();
      }
    },
    setMfpayment(price, orderid) {
      let data = JSON.stringify({
        // clientcode: this.uid,
        // ordernumber: [`${orderid}`],
        // amount: `${price}`,
        client_code: this.uid,
        order_number: `${orderid}`,
        total_amount: `${price}`,

        acc_number: this.mf_accact.Bank_AcNo,
        ifsc: this.mf_accact.IFSC_Code,
        bank_name: this.mf_accact.Bank_Name,

        mode_of_payment: this.mfpayinmode,
        internal_ref_no: "",
        // neft_reference: this.mfpayinmode == "NIFT" ? this.mfpainids : "",
        mandate_id: this.mf_tenure ? this.mf_mandate.MandateId : "",
        vpa_id: this.mfpayinmode == "UPI" ? this.mfpainids : "",
        loop_back_url: window.location.href,
        allow_loop_back: "Y",
      });

      let config = {
        method: "post",
        url: apiurl.mfapi + "/all_payment",
        // url: apiurl.mfapi + "/payment",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((res) => {
          axiosThis.orderpoploader = false;
          if (res.data.stat == "Ok") {
            if (res.data.type == "UPI") {
              axiosThis.snackAlert(1, res.data.msg ? res.data.msg : res.data);
              axiosThis.closeMenudialog("mforder");
            } else {
              axiosThis.menudata["payment"] = true;
              var payment = document.getElementById("payment");
              payment.innerHTML = res.data.resp;
              setTimeout(() => {
                window.open(`${apiurl.mfapi}${res.data.file}`, "_blank");
              }, 600);
            }
            // window.open(`${apiurl.mfapi}${res.data.file}`);
            // '_blank' {
            //   "data": "/static/payment_html/1033107555.html",
            //   "stat": "Ok"
            // }
          } else {
            axiosThis.mforderdialog = false;
            axiosThis.snackAlert(2, res.data.msg ? res.data.msg : res.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeMenudialog(type) {
      if (type == "alert") {
        this.alertdialog = false;
      } else if (type == "order") {
        this.orderdialog = false;
        this.buyrsell = false;
      } else if (type == "mforder") {
        this.mforderdialog = false;
      } else if (type == "bondorder") {
        this.bondorderdialog = false;
      } else if (type == "iposorder") {
        this.iposorderdialog = false;
      }
      // if (type != 'mforder') {
      //   let unwsdata = `${this.menudata[0].exch}|${this.menudata[0].token}#`
      //   Datafeed.unsubscribeQuotesScreener(unwsdata);
      // }
      this.menudata = [];
    },
    myOnLine() {
      if (navigator.onLine) {
        // console.log("Connected to internet.");
      } else {
        // console.log("Unable to connect to internet.");
      }
    },
    showAlertmode() {
      window.location.reload();
    },
    setWebsocket(flow, data) {
      let wsstat = sessionStorage.getItem("wsstat");
      if (wsstat == "Ok") {
        if (flow == "sub") {
          Datafeed.subscribeQuotesChain(data.flat(1), this.optionChainDataParse, undefined);
        } else if (flow == "unsub") {
          let unsubscribeList = "";
          data.map((symbol) => {
            return (unsubscribeList += `${symbol.exch}|${symbol.token}#`);
          });
          Datafeed.unsubscribeQuotesScreener(unsubscribeList);
        }
      } else {
        setTimeout(() => {
          this.setWebsocket(flow, data);
        }, 1000);
      }
    },

    optionChainDataParse(data) {
      if (this.wsstocksdata && this.wsstocksdata.tok && this.wsstocksdata.rawdata) {
        eventBus.$emit("web-scoketConn", data[0].v, this.wsstocksdata.raw);
      }

      if (this.menudata[0] && this.menudata[0].token == data[0].v.token) {
        this.menudata["ltp"] = Number(data[0].v.lp).toFixed(2);
        this.menudata["ch"] = Number(data[0].v.ch) > 0 || Number(data[0].v.ch) < 0 ? Number(data[0].v.ch).toFixed(2) : 0;
        this.menudata["chp"] = Number(data[0].v.chp) > 0 || Number(data[0].v.chp) < 0 ? Number(data[0].v.chp).toFixed(2) : 0;

        let tag = document.getElementById(`laypopltp`);
        if (tag) {
          tag.innerHTML = this.menudata.ltp;

          document.getElementById(`laypopch`).innerHTML = this.menudata.ch;
          document.getElementById(`laypopchp`).innerHTML = this.menudata.chp;
          eventBus.$emit("color-event", "laypopchpclr", this.menudata.ch > 0 ? "maingreen--text" : this.menudata.ch < 0 ? "mainred--text" : "subtext--text");
        }
        // this.menudata['high_price'] = (Number(data[0].v.high_price) > 0 || Number(data[0].v.high_price) < 0) ? (Number(data[0].v.high_price).toFixed(2)) : 0;
        // this.menudata['low_price'] = (Number(data[0].v.low_price) > 0 || Number(data[0].v.low_price) < 0) ? (Number(data[0].v.low_price).toFixed(2)) : 0;
        // this.menudata['open_price'] = (Number(data[0].v.open_price) > 0 || Number(data[0].v.open_price) < 0) ? (Number(data[0].v.open_price).toFixed(2)) : 0;
        // this.menudata['close_price'] = (Number(data[0].v.prev_close_price) > 0 || Number(data[0].v.prev_close_price) < 0) ? (Number(data[0].v.prev_close_price)) : 0;
        // this.menudata['volume'] = Number(data[0].v.volume) > 0 ? (Number(data[0].v.volume).toFixed(2)) : 0;
        // // this.menudata['ask_qty'] = data[0].v.ask_qty;
        // this.menudata['ask'] = data[0].v.ask;
        // // this.menudata['bid_qty'] = data[0].v.bid_qty;
        // this.menudata['bid'] = data[0].v.bid;
        // this.menudata['bid_qty'] = (data[0].v.bid_qty > 0 || data[0].v.ask_qty > 0) ? (((data[0].v.bid_qty - (data[0].v.bid_qty + data[0].v.ask_qty)) / (data[0].v.bid_qty + data[0].v.ask_qty)) * 100 + 100).toFixed(2) : 0;
        // this.menudata['ask_qty'] = (data[0].v.bid_qty > 0 || data[0].v.ask_qty > 0) ? Math.abs(100 - this.menudata.bid_qty).toFixed(2) : 0;
        // this.menudata['depth'] = data[0].v.depth;
        // this.menudata['bitmax'] = Math.max(...this.menudata.depth.bids.map((o) => o.volume));
        // this.menudata['askmax'] = Math.max(...this.menudata.depth.asks.map((o) => o.volume));
        // this.$set(this.menudata, data[0].v.lp);
      }
    },
    NumberValid($event) {
      if (($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105)) {
        // 0-9 only
      } else {
        $event.preventDefault();
      }
    },
    getCall() {
      if (this.$refs.form.validate() == true) {
        window.open(`https://oa.mynt.in/?ref=zws&mobile=${this.mobile}`);
        this.$refs.form.reset();
      }
    },
    playSound(stat) {
      const say = stat == "REJECTED" || stat == "CANCELED" ? this.failSound : this.succSound;
      const audio = new Audio(say);
      audio.addEventListener("canplaythrough", () => {
        audio.play();
      });
    },
    setEscape() {
      if (this.snackbar) {
        this.snackbar = false;
      }

      if (this.showalert) {
        this.showalert = false;
      }
    },
    closeRisk() {
      sessionStorage.setItem("risk", 0);
      this.riskdialog = false;
    },
  },
  components: {
    AppBar,
    WatchList,
    "v-snackbars": VSnackbars,
  },
};
</script>

<style>
@import "../../../src/assets/style.css";

.v-tabs-bar {
  height: 40px !important;
}

.default-tab .v-tabs-bar {
  height: 48px !important;
}
</style>
